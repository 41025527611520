.hackathonPage {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.eventIntro {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: calc(75vh - var(--nav_height));
	background-image: url(../../assets/webp/hackathon_bg.webp);
	background-size: 100% 100%;
	background-repeat: no-repeat;
	position: relative;
	padding: 0 2rem;
	gap: .5rem;

	@media screen and (min-width: 730px) {
        height: calc(75vh - var(--nav_height));
        padding: 0 7.5rem;
		gap: .9rem;
	}
	@media screen and (min-width: 1000px) {
        height: calc(100vh - var(--nav_height));
        padding: 0 8.5rem;
		gap: 1rem;
	}
	@media screen and (min-width: 1400px) {
        padding: 0 12rem;
		gap: 1.5rem;
	}
	@media screen and (min-width: 1700px) {
		padding: 0 17rem;
		gap: 2rem;
	}

	p {
		text-align: center;
		font-size: 1.8rem;
		color: white;
		font-weight: 700;

		@media screen and (min-width: 730px) {
            font-size: 2.3rem;
		}
		@media screen and (min-width: 1000px) {
            font-size: 2.8rem;
		}
		@media screen and (min-width: 1400px) {
            font-size: 4rem;
		}
		@media screen and (min-width: 1700px) {
			font-size: 5.4rem;
		}

		span {
			background: linear-gradient(98deg, #00F0FF 31.82%, #BD00FF 82.8%);
			background-clip: text;
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;
		}
	}

	button {
		cursor: pointer;
		outline: none;
		border: none;
		background: linear-gradient(97deg, #4200FF 15.33%, #0094FF 95.71%);
		// box-shadow: 0px 8px 3.1px 0px rgba(255, 255, 255, 0.25) inset,
		// 	0px -6px 4px 0px rgba(0, 0, 0, 0.25) inset;
		color: white;
		font-weight: 700;
		border-radius: 50rem;
		font-size: .9rem;
		padding: .8rem 1.2rem;
		z-index: 2;

		@media screen and (min-width: 730px) {
            font-size: .8rem;
			padding: .7rem 1.1rem;
		}
		@media screen and (min-width: 1000px) {
            font-size: 1rem;
			padding: .9rem 1.4rem;
		}
		@media screen and (min-width: 1400px) {
            font-size: 1.4rem;
			padding: 1.2rem 1.8rem;
		}
		@media screen and (min-width: 1700px) {
			font-size: 1.8rem;
			padding: 1.6rem 2.4rem;
		}
	}

	button:active {
		box-shadow: 0px 8px 3.1px 0px rgba(177, 177, 177, 0.25) inset,
			0px -6px 4px 0px rgba(0, 0, 0, 0.376) inset;
		transform: translateY(3px);
	}
}

.eventIntro::before {
	position: absolute;
	content: '';
	background-image: url(../../assets/webp/binary_bg_white.webp);
	background-size: cover;
	background-repeat: no-repeat;
	opacity: 0.2;
	width: 100%;
	height: 100%;
	pointer-events: none;
	z-index: 1;
}

.eventDescription {
	width: 100%;
	display: flex;
	flex-direction: column;
	padding: 1.8rem;
	gap: 2.8rem;
	background-color: black;
	position: relative;
	z-index: 2;

	@media screen and (min-width: 730px) {
        padding: 3.5rem;
        gap: 2.8rem;
	}
	@media screen and (min-width: 1000px) {
        padding: 4rem 4.5rem;
        gap: 3.5rem;
	}
	@media screen and (min-width: 1400px) {
        padding: 6rem 6.5rem;
        gap: 5rem;
	}
	@media screen and (min-width: 1700px) {
		padding: 8rem 8.5rem;
		gap: 6.5rem;
	}

	.descriptionCategory {
		width: 100%;
		display: flex;
		flex-direction: column;
		gap: .8rem;

		@media screen and (min-width: 730px) {
            gap: .9rem;
		}
		@media screen and (min-width: 1000px) {
            gap: 1rem;
		}
		@media screen and (min-width: 1400px) {
            gap: 1.5rem;
		}
		@media screen and (min-width: 1700px) {
			gap: 2rem;
		}

		.categoryHeading {
			color: #fff;
			font-weight: 700;
			font-size: 1.4rem;

			@media screen and (min-width: 730px) {
                font-size: 1.3rem;
			}
			@media screen and (min-width: 1000px) {
                font-size: 1.6rem;
			}
			@media screen and (min-width: 1400px) {
                font-size: 2.3rem;
			}
			@media screen and (min-width: 1700px) {
				font-size: 3rem;
			}

			span {
				background: linear-gradient(
					97deg,
					#0057ff 11.94%,
					#00a3ff 45.19%
				);
				background-clip: text;
				-webkit-background-clip: text;
				-webkit-text-fill-color: transparent;
			}

			.rewards {
				background: linear-gradient(112deg, #fff504 0.23%, #f09 73.36%);
				background-clip: text;
				-webkit-background-clip: text;
				-webkit-text-fill-color: transparent;
			}
		}

		.categoryText {
			width: 100%;
			display: flex;
			flex-direction: column;
			gap: .9rem;
			font-size: 1rem;

			@media screen and (min-width: 730px) {
                gap: .9rem;
				font-size: .9rem;
			}
			@media screen and (min-width: 1000px) {
                gap: 1rem;
				font-size: 1rem;
			}
			@media screen and (min-width: 1400px) {
                gap: 1.5rem;
				font-size: 1.5rem;
			}
			@media screen and (min-width: 1700px) {
				gap: 2rem;
				font-size: 2rem;
			}

			p {
				color: #e8e8e8;
			}

			table {
				border-radius: 1rem;
				width: 100%;
				font-weight: 700;
				border: none;
				border-collapse: collapse;
				color: white;

                @media screen and (min-width: 1000px) {
                    border-radius: 2rem;
                }

				td, th {
					border: none;
					padding: .9rem;

					@media screen and (min-width: 730px) {
                        padding: .9rem;
					}
					@media screen and (min-width: 1000px) {
                        padding: 1rem;
					}
					@media screen and (min-width: 1400px) {
                        padding: 1.5rem;
					}
					@media screen and (min-width: 1700px) {
						padding: 2rem;
					}
				}
			}

			a {
				width: max-content;
				text-decoration: underline;
				color: var(--zunu_primary_color);
				cursor: pointer;
                font-weight: 700;
			}

			.cardsContainer {
				width: 100%;
				display: flex;
                flex-wrap: wrap;
				gap: 1.2rem;
				z-index: 2;

				@media screen and (min-width: 730px) {
                    flex-wrap: nowrap;
                    gap: 2rem;
				}
				@media screen and (min-width: 1000px) {
                    gap: 2.7rem;
				}
				@media screen and (min-width: 1400px) {
                    gap: 3.8rem;
				}
				@media screen and (min-width: 1700px) {
					gap: 5rem;
				}

				.card {
					width: calc(50% - 1.2rem);
					display: flex;
					justify-content: center;
					align-items: center;
					text-align: center;
					border-radius: 1rem;
					font-weight: 700;
					padding: .9rem 1.2rem;
					color: #ffffff;

					@media screen and (min-width: 730px) {
                        border-radius: 1.6rem;
                        width: 100%;
                        padding: 1.7rem 2.2rem;
					}
					@media screen and (min-width: 1000px) {
                        padding: 2rem 3rem;
					}
					@media screen and (min-width: 1400px) {
                        border-radius: 3rem;
                        padding: 3rem 4.2rem;
					}
					@media screen and (min-width: 1700px) {
						padding: 4rem 6.2rem;
					}
				}

				.card:nth-child(1) {
					background: linear-gradient(145deg, #229CE0 8.12%, #683FDB 76.5%);
				}

				.card:nth-child(2) {
					background: linear-gradient(145deg, #04ADC4 7.23%, #0FC378 79.19%);
				}

				.card:nth-child(3) {
					background: linear-gradient(124deg, #45B1FF 7.71%, #FF4A4A 87.97%);
                    flex-grow: 1;
				}
			}
		}
	}
}

.eventDescription::before{
	position: absolute;
	content: " ";
	top: 0;
	left: 0;
	background: url(../../assets/webp/hackathon_description_bg.webp);
	background-size: 95%;
	background-position: 0 75%;
	background-repeat: no-repeat;
	margin: auto;
	width: 100%;
	height: 100%;
	z-index: 1;
	pointer-events: none;
	opacity: 0.25;
}
