@mixin sectionMargin {
	margin: 2rem 0;

	@media screen and (min-width: 730px) {
		margin: 2.5rem 0;
	}

	@media screen and (min-width: 1000px) {
		margin: 3.2rem 0;
	}

	@media screen and (min-width: 1400px) {
		margin: 4.5rem 0;
	}

	@media screen and (min-width: 1700px) {
		margin: 6rem 0;
	}
}