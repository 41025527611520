@import '../../styles/abstracts';

.productPage {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	@media screen and (min-width: 550px) {
	}

	@media screen and (min-width: 730px) {
	}

	@media screen and (min-width: 1000px) {
	}

	@media screen and (min-width: 1200px) {
	}

	@media screen and (min-width: 1400px) {
	}

	@media screen and (min-width: 1700px) {
	}
}

.productIntro {
	width: 100%;
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 1;
	background: linear-gradient(180deg, rgba(122, 223, 255, 0.20) 0%, rgba(247, 247, 247, 0.20) 100%), #F7F7F7;
	height: calc(100vh - var(--nav_height) - env(safe-area-inset-bottom));

	@media screen and (min-width: 1400px) {
	}

	@media screen and (min-width: 1700px) {
	}

	.fileTransferDoodle {
		position: absolute;
		bottom: 0rem;
		left: 50%;
		transform: translateX(-50%);
		height: 6rem;
		z-index: 1;

		@media screen and (min-width: 400px) {
			height: 7.5rem;
		}

		@media screen and (min-width: 730px) {
			height: 13rem;
			bottom: 1rem;
		}

		@media screen and (min-width: 1000px) {
			height: 12rem;
			bottom: 1.2rem;
		}

		@media screen and (min-width: 1000px) {
			height: 8rem;
			bottom: 0rem;
		}

		@media screen and (min-width: 1400px) {
			height: 12rem;
			bottom: 0;
		}

		@media screen and (min-width: 1700px) {
			height: 15rem;
		}
	}

	.userPurple {
		position: absolute;
		height: 5rem;
		right: 0rem;
		bottom: 5rem;
    	z-index: 1;

		@media screen and (min-width: 730px) {
			height: 5rem;
			right: 5rem;
			bottom: 20rem;
		}

		@media screen and (min-width: 1000px) {
			height: 6rem;
			right: 7rem;
			bottom: 9rem;
		}

		@media screen and (min-width: 1400px) {
			height: 10rem;
			right: 8rem;
			bottom: 8rem;
		}

		@media screen and (min-width: 1700px) {
			height: 12rem;
			right: 11rem;
			bottom: 7rem;
		}
	}
}

.productIntro::after {
	content: ' ';
	display: block;
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	opacity: 0.1;
	background-image: url(../../assets/svg/mail_intro_bg.svg);
	background-repeat: no-repeat;
	background-position: 0% 0;
	background-size: cover;
	z-index: 0;
}

.productIntroText {
	text-align: center;
	position: relative;
	z-index: 1;
	margin-top: -3rem;

	@media screen and (min-width: 730px) {
		margin-top: -6rem;
	}

	@media screen and (min-width: 1000px) {
		margin-top: -3.5rem;
	}
	@media screen and (min-width: 1400px) {
		margin-top: -3rem;
	}

	@media screen and (min-width: 1700px) {
		margin-top: -5rem;
	}

	p {
		color: #1c1b1f;
		font-weight: 600;
		font-size: 2.7rem;
		padding: 0 1rem;

		span {
			color: #ffffff;
			background-color: #1c1b1f;
		}

		@media screen and (min-width: 365px) {
			padding: 0rem 2rem;
		}

		@media screen and (min-width: 730px) {
			padding: 0rem;
			font-size: 2.8rem;
		}

		@media screen and (min-width: 1000px) {
			font-size: 3.2rem;
		}
		@media screen and (min-width: 1400px) {
			font-size: 3.8rem;
		}

		@media screen and (min-width: 1700px) {
			font-size: 5rem;
		}
	}

	.cloudDoodle {
		position: absolute;
		right: 2rem;
		height: 7rem;
		top: -2.5rem;

		@media screen and (min-width: 730px) {
			right: 0;
			height: 8rem;
			top: -5rem;
		}

		@media screen and (min-width: 1000px) {
			height: 8rem;
			top: -5rem;
			right: -3rem;
		}

		@media screen and (min-width: 1400px) {
			height: 9rem;
			top: -5.5rem;
		}

		@media screen and (min-width: 1700px) {
			height: 10rem;
			top: -4.5rem;
		}
	}

	.userPink {
		position: absolute;
		bottom: 10.5rem;
		height: 4.3rem;
		left: -.5rem;

		@media screen and (min-width: 400px) {
			bottom: 6.5rem;
			height: 5rem;
			left: 0rem;
		}

		@media screen and (min-width: 550px) {
			bottom: 2rem;
			height: 4rem;
			left: 1rem;
		}

		@media screen and (min-width: 730px) {
			bottom: 0rem;
			height: 5rem;
			left: -4rem;
		}

		@media screen and (min-width: 1000px) {
			bottom: .5rem;
			height: 6rem;
			left: -5rem;
		}

		@media screen and (min-width: 1400px) {
			bottom: 0px;
			height: 8rem;
			left: -7.5rem;
		}

		@media screen and (min-width: 1700px) {
			height: 9rem;
			left: -12rem;
			bottom: 3rem;
		}
	}
}

.productUSP {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 0 1rem;
	gap: 2.6rem;
	@include sectionMargin;

	@media screen and (min-width: 730px) {
		padding: 0 2rem;
		gap: 1.5rem;
	}

	@media screen and (min-width: 1000px) {
		padding: 0 3rem;
		gap: 1.8rem;
	}

	@media screen and (min-width: 1400px) {
		padding: 0 3.5rem;
		gap: 2.5rem;
	}

	@media screen and (min-width: 1700px) {
		padding: 0 5.25rem;
		gap: 3.25rem;
	}

	.uspHeading {
		color: #1c1b1f;
		font-weight: 600;
		text-align: center;
		font-size: 1.7rem;
		padding: 0 1rem;

		@media screen and (min-width: 730px) {
			font-size: 1.6rem;
			padding: 0 5rem;
		}

		@media screen and (min-width: 1000px) {
			font-size: 2rem;
			padding: 0 8rem;
		}

		@media screen and (min-width: 1400px) {
			font-size: 2.8rem;
			padding: 0 10rem;
		}

		@media screen and (min-width: 1700px) {
			font-size: 3.75rem;
			padding: 0 15rem;
		}

		span{
			color: var(--zunu_primary_color);
		}
	}

	.uspContainer {
		width: 100%;
		border-radius: 2rem;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		justify-content: space-between;
		padding: 2rem;
		gap: 2.2rem;

		@media screen and (min-width: 730px) {
			flex-direction: row;
			padding: 1rem 2rem;
			padding: 0;
			gap: 1.3rem;
		}

		@media screen and (min-width: 1000px) {
			padding: 1.4rem 2.5rem;
			padding: 0;
			gap: 2rem;
		}

		@media screen and (min-width: 1400px) {
			padding: 3.2rem 7.5rem;
			padding: 4rem;
			padding: 0;
			gap: 2rem;
		}

		@media screen and (min-width: 1700px) {
			padding: 4rem 10rem;
			padding: 0;
			gap: 2.5rem;
		}
	}

	.usptextContainer{
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: start;
		gap: .5rem;

		@media screen and (min-width: 730px) {
			gap: 1.7rem;
			padding: 2rem 0rem 2rem 2rem;
		}

		@media screen and (min-width: 1000px) {
			gap: 2rem;
			padding: 2.5rem 0rem 2.5rem 2.5rem;
		}

		@media screen and (min-width: 1400px) {
			gap: 3rem;
			padding: 4rem 0rem 4rem 4rem;
		}

		@media screen and (min-width: 1700px) {
			gap: 4rem;
			padding: 4.5rem 0rem 4.5rem 4.5rem;
		}
	}

	.uspText {
		font-weight: 600;
		width: 100%;
		font-size: 1.25rem;
		gap: .5rem;
		display: flex;
		flex-direction: column;
		color: #444343;

		@media screen and (min-width: 730px) {
			font-size: 1.2rem;
			gap: .3rem;
		}

		@media screen and (min-width: 1000px) {
			font-size: 1.4rem;
			gap: .3rem;
		}

		@media screen and (min-width: 1400px) {
			font-size: 2.2rem;
			gap: .5rem;
		}

		@media screen and (min-width: 1700px) {
			font-size: 2.6rem;
			gap: .5rem;
		}

		span {
			color: #ee1818;
		}

		.uspTextGreen {
			color: #2fab02;
		}
	}

	.uspText p:nth-child(1){
		font-size: 1.75rem;
		color: black;

		@media screen and (min-width: 730px) {
			font-size: 1.7rem;
		}

		@media screen and (min-width: 1000px) {
			font-size: 2rem;
		}

		@media screen and (min-width: 1400px) {
			font-size: 3.3rem;
		}

		@media screen and (min-width: 1700px) {
			font-size: 4rem;
		}
	}

	.uspTextCardsConatiner{
		width: 100%;
		// display: grid;
		// grid-template-columns: repeat(2, 1fr);

		display: flex;
		flex-wrap: wrap;
		gap: 2rem;

		@media screen and (min-width: 730px) {
			gap: .8rem;
			display: grid;
			grid-template-columns: repeat(3, 1fr);
		}

		@media screen and (min-width: 1000px) {
			gap: 1rem;
		}

		@media screen and (min-width: 1400px) {
			gap: 1.5rem;
		}

		@media screen and (min-width: 1700px) {
			gap: 2rem;
		}

		.uspTextCard{
			padding: .7rem;
			font-size: 1rem;
			// width: 100%;
			// height: 100%;
			width: calc(50% - 1rem);
			display: flex;
			flex-direction: column;
			align-items: center;
			flex-grow: 1;
			background: white;
			border-radius: 1rem;
			font-size: .7rem;
			color: #1C1B1F;
			gap: .45rem;

			@media screen and (min-width: 730px) {
				flex-grow: 0;
				height: 100%;
				width: 100%;
				// width: calc(33.3% - .53rem);
				font-size: .6rem;
				padding: .5rem;
				gap: .3rem;
			}
	
			@media screen and (min-width: 1000px) {
				// width: calc(33.3% - .66rem);
				font-size: .7rem;
				padding: .8rem;
				gap: .4rem;
			}
	
			@media screen and (min-width: 1400px) {
				// width: calc(33.3% - 1rem);
				font-size: .9rem;
				padding: 1.1rem;
				gap: .6rem;
			}
	
			@media screen and (min-width: 1700px) {
				// width: calc(33.3% - 2rem);
				font-size: .95rem;
				padding: 1rem;
				gap: .5rem;
			}

			span{
				color: #F30F0F;
				font-weight: 500;
			}

			.usptextGreen{
				color: #33BB02;
				font-weight: 500;
			}

			svg{
				height: 1.5rem;

				@media screen and (min-width: 730px) {
					height: 1.2rem;
				}
				@media screen and (min-width: 1000px) {
					height: 1rem;
				}
				@media screen and (min-width: 1400px) {
					height: 1.5rem;
				}
				@media screen and (min-width: 1700px) {
					height: 2rem;
				}
			}
		}
	}

	figure {
		width: 100%;
		display: flex;
		align-items: flex-end;
		justify-content: center;

		@media screen and (min-width: 730px) {
			width: 80%;
		}

		@media screen and (min-width: 1000px) {
			width: 75%;
		}

		@media screen and (min-width: 1400px) {
			width: 45%;
		}

		@media screen and (min-width: 1700px) {
			width: 65%;
		}
	}

	img {
		width: 100%;
		aspect-ratio: .87;
		aspect-ratio: auto;

		@media screen and (min-width: 730px) {
			aspect-ratio: .87;
			aspect-ratio: auto;
		}
	}
}

.uspContainer:nth-child(odd) {
	@media screen and (min-width: 730px) {
		flex-direction: row-reverse;
	}

	.usptextContainer{

		@media screen and (min-width: 730px) {
			padding: 2rem 2rem 2rem 0rem;
		}

		@media screen and (min-width: 1000px) {
			padding: 2.5rem 2.5rem 2.5rem 0rem;
		}

		@media screen and (min-width: 1400px) {
			padding: 4rem 4rem 4rem 0rem;
		}

		@media screen and (min-width: 1700px) {
			padding: 4.5rem 4.5rem 4.5rem 0rem;
		}
	}
}

.usp1 {
	background: #ffeaea;

	.uspTextCard{
		box-shadow: 0px 3.435px 2.748px 0px rgba(234, 113, 113, 0.04), 0px 8.687px 6.95px 0px rgba(234, 113, 113, 0.05), 0px 17.721px 14.177px 0px rgba(234, 113, 113, 0.07), 0px 36.502px 29.201px 0px rgba(234, 113, 113, 0.08), 0px 100px 80px 0px rgba(234, 113, 113, 0.12);
	}
}

.usp2 {
	background: #F3ECFF;

	.uspTextCard{
		box-shadow: 0px 2.767px 2.214px 0px rgba(108, 106, 255, 0.02), 0px 6.65px 5.32px 0px rgba(108, 106, 255, 0.03), 0px 12.522px 10.017px 0px rgba(108, 106, 255, 0.04), 0px 22.336px 17.869px 0px rgba(108, 106, 255, 0.04), 0px 41.778px 33.422px 0px rgba(108, 106, 255, 0.05), 0px 100px 80px 0px rgba(108, 106, 255, 0.07);
	}
}

.usp3 {
	background: #f3ffea;

	.uspTextCard{
		box-shadow: -1px 5px 16px 0px rgba(109, 141, 173, 0.20);
	}
}

.productBanner {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	// padding: 0 3rem;
	gap: 2rem;

	@media screen and (min-width: 730px) {
		padding: 0 4.2rem;
		gap: .7rem;
	}

	@media screen and (min-width: 1000px) {
		padding: 0 4.5rem;
		gap: .8rem;
	}

	@media screen and (min-width: 1400px) {
		padding: 0 4.2rem;
		gap: 1.2rem;
	}

	@media screen and (min-width: 1700px) {
		padding: 0 5rem;
		gap: 1.5rem;
	}

	.bannerHeading {
		color: #1c1b1f;
		font-weight: 600;
		padding: 2rem 3rem 0 3rem;
		font-size: 1.7rem;

		@media screen and (min-width: 730px) {
			text-align: center;
			padding: 1.7rem 4rem 0 4rem;
			font-size: 1.6rem;
		}

		@media screen and (min-width: 1000px) {
			padding: 2rem 6rem 0 6rem;
			font-size: 2rem;
		}

		@media screen and (min-width: 1400px) {
			padding: 4rem 13rem 0 13rem;
			font-size: 2.8rem;
		}

		@media screen and (min-width: 1700px) {
			padding: 3rem 12rem 0 12rem;
			font-size: 3.75rem;
		}

		span{
			color: var(--zunu_primary_color);
		}
	}

	figure {
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	img {
		width: 96%;
		aspect-ratio: 0.728;

		@media screen and (min-width: 730px) {
			width: 100%;
			aspect-ratio: 2.014;
		}
	}
}

.productCardsConatiner {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	// justify-content: space-between;
	justify-content: center;
	background: #e4f4f0;
	padding: 2rem;
	grid-template-columns: repeat(2, 1fr);
	gap: 2rem;
	@include sectionMargin;

	@media screen and (min-width: 730px) {
		padding: 3.5rem 4.5rem;
		grid-template-columns: repeat(3, 1fr);
		gap: 2.5rem;
	}

	@media screen and (min-width: 1000px) {
		padding: 1.8rem 5.7rem;
		gap: 3rem;
	}

	@media screen and (min-width: 1400px) {
		padding: 6.5rem 8rem;
		gap: 4.36rem;
	}

	@media screen and (min-width: 1700px) {
		padding: 6.5rem 8rem;
		gap: 4.36rem;
	}

	.productCard {
		border-radius: 1rem;
		background: #fff;
		display: flex;
		flex-direction: column;
		align-items: center;
		width: calc(50% - 1rem);
		gap: 1rem;
		padding: 1rem 1.3rem;

		@media screen and (min-width: 730px) {
			width: calc(33.3% - 3.3rem);
			gap: 1.2rem;
			padding: 1.6rem 1.2rem;
		}

		@media screen and (min-width: 1000px) {
			width: calc(33.3% - 2rem);
			gap: 1.5rem;
			padding: 2rem 1.5rem;
		}

		@media screen and (min-width: 1400px) {
			width: calc(33.3% - 3.5rem);
			gap: 2.14rem;
			padding: 2.8rem 2rem;
		}

		@media screen and (min-width: 1700px) {
			width: calc(33.3% - 3.5rem);
			gap: 2.14rem;
			padding: 2.8rem 2rem;
		}
	}

	svg {
		height: 2.5rem;

		@media screen and (min-width: 730px) {
			height: 3.3rem;
		}

		@media screen and (min-width: 1000px) {
			height: 4rem;
		}

		@media screen and (min-width: 1400px) {
			height: 4.3rem;
		}

		@media screen and (min-width: 1700px) {
			height: 6rem;
		}
	}

	p {
		color: black;
		font-size: 0.7rem;

		span {
			font-weight: 500;
		}

		@media screen and (min-width: 730px) {
			font-size: .8rem;
		}

		@media screen and (min-width: 1000px) {
			font-size: 1rem;
		}

		@media screen and (min-width: 1400px) {
			font-size: 1.5rem;
		}

		@media screen and (min-width: 1700px) {
			font-size: 1.5rem;
		}
	}
}

.setApart {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	background: #e8ecfd;
	padding: .5rem;
	gap: 1.35rem;

	@media screen and (min-width: 730px) {
		padding: 5rem 3rem;
		gap: 2.5rem;
	}

	@media screen and (min-width: 1000px) {
		padding: 6rem 4rem;
		gap: 3.2rem;
	}

	@media screen and (min-width: 1400px) {
		padding: 8.5rem 5.4rem;
		gap: 4.5rem;
	}

	@media screen and (min-width: 1700px) {
		padding: 11.5rem 13rem;
		gap: 6rem;
	}

	.setApartHead {
		display: flex;
		flex-direction: column;
		text-align: center;
		gap: 0.5rem;
		padding: 0 2rem;

		@media screen and (min-width: 730px) {
			padding: 0;
			gap: 0.5rem;
		}

		@media screen and (min-width: 1000px) {
			gap: 0.5rem;
		}

		@media screen and (min-width: 1400px) {
			gap: 0.75rem;
		}

		@media screen and (min-width: 1700px) {
			gap: 1rem;
		}

		.heading {
			font-weight: 600;
			color: black;
			font-size: 1.5rem;

			@media screen and (min-width: 730px) {
				font-size: 2.2rem;
			}

			@media screen and (min-width: 1000px) {
				font-size: 2.7rem;
			}

			@media screen and (min-width: 1400px) {
				font-size: 3.8rem;
			}

			@media screen and (min-width: 1700px) {
				font-size: 5rem;
			}
		}

		.subHeading {
			color: #444343;
			font-size: 0.8rem;

			@media screen and (min-width: 730px) {
				font-size: 1rem;
			}

			@media screen and (min-width: 1000px) {
				font-size: 1.2rem;
			}

			@media screen and (min-width: 1400px) {
				font-size: 1.8rem;
			}

			@media screen and (min-width: 1700px) {
				font-size: 2.3rem;
			}
		}
	}

	figure {
		width: 100%;

		@media screen and (min-width: 1400px) {
			width: 90%;
		}

		@media screen and (min-width: 1700px) {
			width: 95%;
		}
	}

	img {
		width: 100%;
		// max-height: calc(100vh - var(--nav_height) - 2rem);
		aspect-ratio: 0.519;

		@media screen and (min-width: 730px) {
			aspect-ratio: 1.74;
		}
	}
}

.productFeatures {
	width: 100%;
	display: flex;
	flex-direction: column;
	@include sectionMargin;

	.productFeaturesHeading {
		color: black;
		font-weight: 600;
		text-align: center;
		font-size: 2rem;
		margin-bottom: 2rem;
		padding: 0 1.5rem;

		span {
			color: var(--zunu_primary_color);
		}


		@media screen and (min-width: 730px) {
			font-size: 2.2rem;
			margin-bottom: 1.8rem;
			padding: 0 6rem;
		}

		@media screen and (min-width: 1000px) {
			font-size: 2.7rem;
			margin-bottom: 2rem;
			padding: 0 9rem;
		}

		@media screen and (min-width: 1400px) {
			font-size: 3.8rem;
			margin-bottom: 3rem;
			padding: 0 10rem;
		}

		@media screen and (min-width: 1700px) {
			font-size: 5rem;
			margin-bottom: 4rem;
			padding: 0 15rem;
		}
	}

	.feature {
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: center;
		padding: 2rem 1rem;
		gap: 0rem;

		@media screen and (min-width: 730px) {
			flex-direction: row;
			padding: 2rem 3.5rem;
			gap: 5rem;
		}

		@media screen and (min-width: 1000px) {
			padding: 2.5rem 4.4rem;
			gap: 6.6rem;
		}

		@media screen and (min-width: 1400px) {
			padding: 3.5rem 6.2rem;
			gap: 9.4rem;
		}

		@media screen and (min-width: 1700px) {
			padding: 4.6rem 8.2rem;
			gap: 12.5rem;
		}

		.featureText {
			display: flex;
			flex-direction: column;
			width: 100%;
			gap: 0.5rem;
			padding: 0 1.5rem;

			@media screen and (min-width: 730px) {
				padding: 0;
				gap: .65rem;
			}

			@media screen and (min-width: 1000px) {
				width: 40%;
				gap: .8rem;
			}

			@media screen and (min-width: 1400px) {
				gap: 1.2rem;
			}

			@media screen and (min-width: 1700px) {
				gap: 1.5rem;
			}

			.featureHeading {
				font-weight: 600;
				color: #1c1b1f;
				font-size: 1.3rem;

				@media screen and (min-width: 730px) {
					font-size: 1.2rem;
				}

				@media screen and (min-width: 1000px) {
					font-size: 1.3rem;
				}

				@media screen and (min-width: 1400px) {
					font-size: 1.8rem;
				}

				@media screen and (min-width: 1700px) {
					font-size: 2.5rem;
				}
			}

			ul {
				color: #444343;
				font-size: 0.9rem;

				@media screen and (min-width: 730px) {
					font-size: .9rem;
				}

				@media screen and (min-width: 1000px) {
					font-size: 1rem;
				}

				@media screen and (min-width: 1400px) {
					font-size: 1.2rem;
				}
				@media screen and (min-width: 1700px) {
					font-size: 1.5rem;
				}

				li {
					margin-left: 1.2rem;
					margin-bottom: 0.5rem;

					@media screen and (min-width: 1400px) {
						margin-left: 2rem;
					}
				}
			}
		}

		figure {
			width: 100%;

			@media screen and (min-width: 1000px) {
				width: 60%;
			}

			@media screen and (min-width: 1400px) {
				width: 50%;
			}

			@media screen and (min-width: 1700px) {
				width: 40%;
			}

			img {
				aspect-ratio: 1;
				width: 100%;
			}
		}
	}

	.feature:nth-child(odd) {
		// background: #e4f3ff;

		@media screen and (min-width: 730px) {
			flex-direction: row-reverse;
		}
	}

	.feature:nth-child(2){
		background: #D2F8EE;
	}

	.feature:nth-child(4){
		background: #E6F4FF;
	}

	.feature:nth-child(6){
		background: #FFE6F4;
	}

	.feature:nth-child(8){
		background: #F7F2FF;
	}

	.feature:nth-child(10){
		background: #FFF9E8;
	}
}

.testimonalsContainer {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 0 1rem;
	gap: 1rem;
	background: black;
	// @include sectionMargin;

	@media screen and (min-width: 400px) {
		padding: 0 2rem;
	}

	@media screen and (min-width: 730px) {
		padding: 0 3rem;
		gap: 1.8rem;
	}

	@media screen and (min-width: 1000px) {
		padding: 0 2.5rem;
		gap: 2rem;
	}

	@media screen and (min-width: 1400px) {
		padding: 0 4rem;
		gap: 3rem;
	}

	@media screen and (min-width: 1700px) {
		padding: 0 5rem;
		padding: 6rem 7rem;
		gap: 4rem;
	}

	.testimonialsHeading {
		color: white;
		font-weight: 600;
		font-size: 1.5rem;
		padding: 0 1rem;

		@media screen and (min-width: 730px) {
			padding: 0;
			font-size: 2.2rem;
		}

		@media screen and (min-width: 1000px) {
			font-size: 2	.8rem;
		}

		@media screen and (min-width: 1400px) {
			font-size: 3.8rem;
		}
		@media screen and (min-width: 1700px) {
			font-size: 5rem;
		}
	}
}
