.deleteAccount {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	overflow: hidden;

	.shadow {
		position: absolute;
		border-radius: 50rem;
		width: 18rem;
		aspect-ratio: 1;
		background: #c7daff;
		filter: blur(114.92823028564453px);
		top: -2rem;
		right: -2rem;
	}

	.formWrapper {
		z-index: 1;
		width: 100%;
		max-width: 550px;
		display: flex;
		flex-direction: column;
		padding: 1.5rem;

		@media screen and (min-width: 1000px) {
			border-radius: 1.5rem;
			border: 1px solid #dcdfe4;
			margin: 4rem 0;
		}

		form {
			width: 100%;
			display: flex;
			flex-direction: column;

			.title {
				font-family: 'Plus Jakarta Sans';
				font-weight: 600;
				color: #172b4d;
				font-size: 1.75rem;
				line-height: 2.5rem;
			}

			.emailWrapper,
			.zunuIdWrapper,
			.reasonWrapper,
			.commentWrapper,
			.ctaWrapper {
				width: 100%;
				display: flex;
				flex-direction: column;
			}

			label {
				color: #2c3e5d;
				font-family: 'Plus Jakarta Sans';
				font-weight: 500;
				font-size: 0.875rem;
				line-height: 1.25rem;
			}

			input,
			textarea,
			select,
			.phoneInput {
				outline: none;
				resize: none;
				font-family: 'Inter';
				font-size: 0.875rem;
				border-radius: 0.75rem;
                background: white;
				border: 1px solid #dcdfe4;
				padding: 0.625rem 1rem;
			}

			select {
				-webkit-appearance: none;
				// color: #b3b8c4;
                background: transparent;
			}

			input::placeholder,
			textarea::placeholder {
				color: #b3b8c4;
			}

            .error{
                font-size: .75rem;
                font-family: "Inter";
                color: #cb2029;
            }


			.zunuIdWrapper {
				gap: 1rem;
                margin: 2rem 0 .75rem 0;

				.radiosWrapper {
					display: flex;
					align-items: center;
					gap: 1.4rem;

					.wrapper {
						display: flex;
						align-items: center;
						gap: 0.7rem;
					}
				}

				.phoneInput {
					input {
						border: none;
						padding: 0;
						border-radius: 0;
						margin-left: 1rem;
					}
				}
			}

            .emailWrapper {
				gap: 0.75rem;	
                margin-bottom: .75rem;
			}

			.reasonWrapper {
				margin-bottom: 0.75rem;
				gap: 0.75rem;
			}

			.commentWrapper {
				gap: 0.75rem;

                i{
                    color: #b3b8c4;
                }
			}

			.ctaWrapper {
				margin-top: 3.5rem;

				.title {
					font-size: 1rem;
                    line-height: 1.25rem;
				}

				.subTitle {
					margin: 1rem 0 1.5rem 0;
					font-family: 'Inter';
					font-family: 0.875rem;
                    line-height: 1.5rem;
					color: #626f86;
				}

				button {
					cursor: pointer;
					padding: 0.625rem 1rem;
					color: white;
					font-family: 'Plus Jakarta Sans';
					font-size: 1rem;
					font-weight: 600;
					width: inherit;
					border-radius: 0.75rem;
					background: #cb2029;
				}

                button:disabled{
                    cursor: not-allowed;
                    opacity: .6;
                }
			}
		}
	}
}
