.footer {
	width: 100%;
	max-width: 2500px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	// padding: 1.5rem 1.4375rem 0 1.4375rem;
	padding: 5.03rem 2.33rem 2.06rem;
	position: relative;
	overflow: hidden;
	// background: black;
	background: #172B4D;
	gap: 2rem;


	@media screen and (min-width: 730px) {
		gap: 1.82rem;
		
		padding: 2.14rem 2.37rem;
	}

	@media screen and (min-width: 1024px) {
		gap: 2.23rem;
	
		padding: 2.62rem 2.87rem;
	}

	@media screen and (min-width: 1400px) {
		gap: 3.14rem;
		
		padding: 3.82194rem 14.0625rem 3.70888rem 14.0625rem;
	}

	@media screen and (min-width: 1700px) {
		gap: 4.19rem;
		padding: 5.09594rem 18.75rem 4.94525rem ;
	}

	.footerHeader {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: space-between;
		gap: 4rem;
		//align-items: center;

		@media screen and (min-width: 730px) {
			gap: 0;
			justify-content: space-between;
		}
	}

	.headerLogoContainer {
		display: flex;
		flex-direction: column;
		flex-direction: column-reverse;
		justify-content: space-between;
		z-index: 1;
		// gap: 1.6rem;
		gap: 1.81rem;

		@media screen and (min-width: 730px) {
			flex-direction: row;
			justify-content: space-between;
			width: 100%;
		}
		@media screen and (min-width: 1000px) {
		}
		@media screen and (min-width: 1400px) {
		}
	}
	.zirohLogo {
		width: auto;
		height: 1rem;

		@media screen and (min-width: 320px) {
			height: 1.15rem;
		}

		@media screen and (min-width: 550px) {
			display: none;
			height: 1.5rem;
		}

		@media screen and (min-width: 730px) {
			display: flex;
			height: 1.5rem;
		}

		@media screen and (min-width: 1400px) {
			height: 2rem;
		}
		@media screen and (min-width: 1700px) {
			height: 2.5rem;
		}
	}

	.zunuLogo {
		height: 1rem;

		@media screen and (min-width: 320px) {
			height: 1.15rem;
		}

		@media screen and (min-width: 550px) {
			height: 1.5rem;
		}

		@media screen and (min-width: 730px) {
			height: 1.5rem;
		}

		@media screen and (min-width: 1400px) {
			height: 2rem;
		}
	}

	@media screen and (min-width: 550px) {
		.flag {
			display: none;
		}
	}

	.linksContainer {
		// margin: 1rem 0 2rem 0;
		width: 100%;
		display: flex;
		flex-direction: column;
		flex-wrap: wrap;
		gap: 1.56rem;


		// @media screen and (min-width: 550px) {
		// 	flex-direction: row;
		// 	justify-content: space-between;
		// 	gap: 1.2rem;
		// 	margin: 3rem 0;
		// }

		@media screen and (min-width: 730px) {
			flex-direction: row;
			justify-content: space-between;
			//	gap: 1.5rem;
			// margin: 3rem 0;
		}

		@media screen and (min-width: 1200px) {
			//	gap: 3rem;
		}

		@media screen and (min-width: 1400px) {
			justify-content: space-between;
			// margin: 5.5rem 0;
			//gap: 3rem;
		}

		a {
			text-decoration: none;
			// color: var(--text_heading);
			// color: #c7ceea;
			color: #FFF;
			font-weight: 500;
			font-size: 1.01rem;
			cursor: pointer;

			@media screen and (min-width: 730px) {
				font-size: 0.543rem;
			}

			@media screen and (min-width: 1000px) {
				font-size: .75rem;
			}
			@media screen and (min-width: 1400px) {
				font-size: .9375rem;
			}

			@media screen and (min-width: 1700px) {
				font-size: 1.25rem;
			}
		}
	}

	.privacyMediaContainer {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		gap: 1.25rem;
		// padding-bottom: 2rem;
		z-index: 1;

		@media screen and (min-width: 550px) {
			flex-direction: column;
			gap: 1rem;
			// padding-bottom: 2rem;
		}

		@media screen and (min-width: 730px) {
			flex-direction: row;
			gap: 0.87rem;
			// padding-bottom: 5rem;
		}
		@media screen and (min-width: 1000px) {
			flex-direction: row;
			gap: 1.07rem;
			// padding-bottom: 6rem;
		}
		@media screen and (min-width: 1400px) {
			flex-direction: row;
			gap: 1.5rem;
			// padding-bottom: 8rem;
		}
		@media screen and (min-width: 1700px) {
			flex-direction: row;
			gap: 2rem;
			// padding-bottom: 9rem;
		}
	}
	.privacyLogoContainer {
		//margin-bottom: 4.5rem;
		justify-content: center;
		font-weight: 600;
		color: #ffffff;
		font-size: 1.2rem;
		//gap: 1rem;

		@media screen and (min-width: 550px) {
			font-size: 1.3rem;
		}

		@media screen and (min-width: 730px) {
			font-size: 1rem;
		}

		@media screen and (min-width: 1000px) {
			//	margin-bottom: 6rem;
			font-size: 1.3rem;
		}

		@media screen and (min-width: 1400px) {
			//	margin-bottom: 10rem;
			font-size: 1.7rem;
		}

		@media screen and (min-width: 1700px) {
			font-size: 2.3rem;
			//	margin-bottom: 15rem;
		}

		span {
			background: var(--zunu_primary_color);
		}

		img {
			width: 100%;
			aspect-ratio: 16/9;
		}
		p {
			display: flex;
			//flex-direction: row-reverse;
			justify-content: end;
			font-size: 0.875rem;
			color: var(--Global-White, #fff);

			@media screen and (min-width: 320px) {
				font-size: 0.43669rem;
			}
			@media screen and (min-width: 730px) {
				font-size: 0.38006rem;
			}
			@media screen and (min-width: 1000px) {
				font-size: 0.46644rem;
			}
			@media screen and (min-width: 1400px) {
				font-size: 0.65594rem;
			}
			@media screen and (min-width: 1700px) {
				font-size: 0.875rem;
			}
		}
	}
	.mediaIconContainer {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 1rem;

		a {
			z-index: 1;
		}

		@media screen and (min-width: 730px) {
			gap: 1.14rem;
		}

		@media screen and (min-width: 1000px) {
			gap: 1.4rem;
		}

		@media screen and (min-width: 1400px) {
			gap: 1.97rem;
		}

		@media screen and (min-width: 1700px) {
			gap: 2.62rem;
		}
	}
	.mediaIcons {
		display: flex;
		align-items: center;
		height: 1.3rem;
		z-index: 1;

		@media screen and (min-width: 550px) {
			height: 1.5rem;
		}

		@media screen and (min-width: 730px) {
			height: 1rem;
		}

		@media screen and (min-width: 1000px) {
			height: 1.3rem;
		}

		@media screen and (min-width: 1400px) {
			height: 1.5rem;
		}

		@media screen and (min-width: 1700px) {
			height: 1.8rem;
		}
	}

	.copyright {
		color: #ffffff;
		font-size: 0.8rem;


		
		@media screen and (min-width: 1700px) {
			font-size: 1.2rem;
		}
	}
}

.keepPrivateLogoContainer{
	display: flex;
	flex-direction: column;
	gap: 1.5rem;
	width: 100%;
	align-items: center;

	@media screen and (min-width: 730px) {
		gap: 3.16rem;
	}

	@media screen and (min-width: 1000px) {
		gap: 3.88rem;
	}

	@media screen and (min-width: 1400px) {
		gap: 5.44rem;
	}
	@media screen and (min-width: 1700px) {
		gap: 7.25rem;
	}
}
.keepPrivateLogo{
	height: 5.12rem;

	@media screen and (min-width: 730px) {
		height: 4.46rem;
	}

	@media screen and (min-width: 1000px) {
		height: 5.47rem;
	}

	@media screen and (min-width: 1400px) {
		height: 7.7rem;
	}
	@media screen and (min-width: 1700px) {
		height: 10.2rem;
	}
}