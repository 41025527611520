.button{
    width: 100%;
    border-radius: 50rem;
    padding: .5rem 1rem;
    background-color: var(--btn_bg_color);
    background-color: var(--zunu_primary_color);
    color: var(--btn_text_color);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-weight: 500;
    font-size: .75rem;
    user-select: none;

    @media screen and (min-width: 730px) {
        font-size: .543rem;
        padding: .35rem .65rem;
    }

    @media screen and (min-width: 1000px) {
        padding: .45rem 1rem;
        font-size: .66rem;

    }
    @media screen and (min-width: 1400px) {
        font-size: 1rem;
        padding: .75rem 1.5rem;
    }

    @media screen and (min-width: 1700px) {
        font-size: 1.25rem;
        padding: .75rem 1.5rem;
    }
}

.secondary{
    border-radius: .75rem;
    background: black;
}