$default-row-gap: 1.5rem;
$default-grid-gap: 1.5rem;

// Mixin for responsive row gaps
@mixin responsive-row-gaps {
  gap: $default-row-gap;

  @media screen and (min-width: 730px) {
    gap: .85rem;
  }

  @media screen and (min-width: 1000px) {
    gap: 1rem;
  }

  @media screen and (min-width: 1400px) {
    gap: 1.25rem;
  }

  @media screen and (min-width: 1700px) {
    gap: 2rem;
  }
}

// Mixin for responsive grid gaps
@mixin responsive-grid-gaps {
  gap: $default-grid-gap;

  @media screen and (min-width: 730px) {
    gap: 0.85rem;
  }

  @media screen and (min-width: 1000px) {
    gap: 1rem;
  }

  @media screen and (min-width: 1400px) {
    gap: 1.25rem;
  }

  @media screen and (min-width: 1700px) {
    gap: 2rem;
  }
}

.downloadPage {
	width: 100%;
	display: flex;
	flex-direction: column;
	padding: 4rem 1rem;
	@include responsive-row-gaps;

	@media screen and (min-width: 730px) {
    padding: 3.3rem 2.5rem;
	}

	@media screen and (min-width: 1000px) {
    padding: 4rem 3rem;
	}

	@media screen and (min-width: 1400px) {
		padding: 5.5rem 10.5rem;
	}

	@media screen and (min-width: 1700px) {
		padding: 8rem 18.75rem;
	}

	.pageHeading {
		font-family: 'Plus Jakarta Sans';
		color: #172b4d;
		text-align: left;
		font-weight: 700;
		font-size: 1.75rem;

		@media screen and (min-width: 730px) {
      text-align: center;
      font-size: 1.85rem;
		}

		@media screen and (min-width: 1000px) {
      font-size: 2.3rem;
		}

		@media screen and (min-width: 1400px) {
			font-size: 2.7rem;
		}

		@media screen and (min-width: 1700px) {
			font-size: 3.25rem;
		}
	}

	.downloadsWrapper {
		width: 100%;
		display: grid;
		grid-template-columns: repeat(1, 1fr);
		@include responsive-row-gaps;

		@media screen and (min-width: 730px) {
      grid-template-columns: repeat(2, 1fr);
		}

		.deviceDownloadContainer {
			width: 100%;
			display: flex;
			flex-direction: column;
			gap: 1.5rem;

			@media screen and (min-width: 730px) {
        gap: 1.15rem;
			}

			@media screen and (min-width: 1000px) {
        gap: 1.4rem;
			}

			@media screen and (min-width: 1400px) {
				gap: 1.67rem;
			}

			@media screen and (min-width: 1700px) {
				gap: 2rem;
			}

			.title {
        display: none;
				color: #626f86;
				font-family: 'Plus Jakarta Sans';
				font-weight: 700;
				font-size: 1.5rem;

				@media screen and (min-width: 730px) {
          font-size: 1rem;
				}

				@media screen and (min-width: 1000px) {
          font-size: 1.2rem;
				}

				@media screen and (min-width: 1400px) {
					font-size: 1.46rem;
				}

				@media screen and (min-width: 1700px) {
					font-size: 1.75rem;
				}
			}

			.osDownloadsWrapper {
				width: 100%;
				height: 100%;
				display: grid;
				grid-template-columns: repeat(1, 1fr);
				@include responsive-grid-gaps;

				@media screen and (min-width: 730px) {
          grid-template-columns: repeat(2, 1fr);
				}

				.downloadContainer {
					width: 100%;
					display: flex;
					flex-direction: row;
					align-items: center;
					background: #fafafa;
					border-radius: 1rem;
					padding: 1.5rem;
					gap: 4.3rem;

					@media screen and (min-width: 730px) {
            width: 100%;
            flex-direction: column;
            border-radius: 1rem;
						padding: 2rem 1rem;
						gap: 1.5rem;
					}

					@media screen and (min-width: 1000px) {
            border-radius: 1.3rem;
						gap: 2rem;
					}

					@media screen and (min-width: 1400px) {
						border-radius: 1.5rem;
						padding: 1.5rem;
					}

					@media screen and (min-width: 1700px) {
						padding: 2rem;
					}

					.osIcon {
						width: 5rem;
            aspect-ratio: 1;

						@media screen and (min-width: 730px) {
              width: auto;
              height: 1.8rem;
						}

						@media screen and (min-width: 1000px) {
              height: 2rem;
						}

						@media screen and (min-width: 1400px) {
              height: 2.5rem;
            }

						@media screen and (min-width: 1700px) {
              height: 3rem;
						}
					}

					.contentWrapper {
						width: 100%;
						display: flex;
						justify-content: flex-start;
						gap: 1rem;

						@media screen and (min-width: 730px) {
              justify-content: center;
              width: 100%;
              gap: .3rem;
						}

						@media screen and (min-width: 1000px) {
              gap: .5rem;
						}

						@media screen and (min-width: 1400px) {
              gap: .7rem;
						}

						@media screen and (min-width: 1700px) {
              gap: 1rem;
						}

						.configsWrapper {
							display: flex;
							flex-direction: column;
							gap: 1rem;

							@media screen and (min-width: 730px) {
                gap: .85rem;
							}

							@media screen and (min-width: 1000px) {
                gap: 1rem;
							}

							@media screen and (min-width: 1400px) {
                gap: 1.25rem;
              }

							@media screen and (min-width: 1700px) {
                gap: 1.5rem;
							}

							.osName {
								color: #172b4d;
								font-family: 'Plus Jakarta Sans';
                text-align: left;
								font-weight: 600;
								font-size: 1rem;

								@media screen and (min-width: 730px) {
                  text-align: center;
                  font-size: .8rem;
								}

								@media screen and (min-width: 1000px) {
                  font-size: .85rem;
								}

								@media screen and (min-width: 1400px) {
                  font-size: 1rem;
								}
							}

							.osConfigs {
								display: flex;
								flex-direction: column;
								align-items: flex-start;
								gap: 1rem;

								@media screen and (min-width: 730px) {
                  align-items: center;
                  gap: .75rem;
								}

								@media screen and (min-width: 1000px) {
                  gap: .8rem;
								}

								@media screen and (min-width: 1400px) {
                  gap: 1rem;
                }

								@media screen and (min-width: 1700px) {
                  gap: 1.25rem;
								}

								.config {
									display: flex;
									align-items: flex-end;
									cursor: pointer;
									gap: 0.35rem;

									.configName {
										color: #626f86;
										font-family: 'Inter';
										text-decoration: underline;
                    text-align: center;
										font-weight: 500;
										font-size: .85rem;

										@media screen and (min-width: 730px) {
                      font-size: .75rem;
										}

										@media screen and (min-width: 1000px) {
                      font-size: .8rem;
										}

										@media screen and (min-width: 1400px) {
                      font-size: .85rem;
										}

										@media screen and (min-width: 1700px) {
                      font-size: 1.125rem;
										}
									}

									.storeLogo {
										height: 1.8rem;

										@media screen and (min-width: 730px) {
                      height: 1.3rem;
										}

										@media screen and (min-width: 1000px) {
                      height: 1.5rem;
										}

										@media screen and (min-width: 1400px) {
                      height: 1.8rem;
										}

										@media screen and (min-width: 1700px) {
                      height: 2.2rem;
										}
									}

									svg {
										height: 0.9rem;
									}
								}
							}
						}
					}

					.qrCode {
						height: 4.4rem;

						@media screen and (min-width: 730px) {
              height: 2.5rem;
						}

						@media screen and (min-width: 1000px) {
              height: 3rem;
						}

						@media screen and (min-width: 1400px) {
              height: 3.6rem;
            }

						@media screen and (min-width: 1700px) {
              height: 4.4rem;
						}
					}
				}
			}
		}

		.desktopDownloads {
			.downloadContainer:nth-child(1) {
				background: #fafafa;
			}

			.downloadContainer:nth-child(2) {
				background: rgba(45, 138, 244, 0.05);
			}
		}

		.mobileDownloads {
			.downloadContainer:nth-child(1) {
				background: #fafafa;
			}

			.downloadContainer:nth-child(2) {
				background: rgba(16, 221, 118, 0.05);
			}


			.contentWrapper {
				justify-content: space-between !important;

        .osName{
          text-align: left !important;
        }

        .osConfigs{
          align-items: flex-start !important;
        }

				.configsWrapper {
					gap: 1rem !important;

          @media screen and (min-width: 730px) {
            gap: .5rem !important;
          }
          
          @media screen and (min-width: 1000px) {
            gap: .6rem !important;
          }
          
          @media screen and (min-width: 1400px) {
            gap: 0.7rem !important;
          }
          
          @media screen and (min-width: 1700px) {
            gap: 0.8rem !important;
          }
				}
			}
		}

		.portableDownloads {
			.downloadContainer:nth-child(1) {
				background: rgba(67, 89, 246, 0.05);
			}
		}
	}
}