.zunu_cards_article {
  display: flex;
  flex-direction: column;
  gap: 0.827rem;

  border-radius: 1.5rem;

  height: inherit;

  padding: 1.15rem 1.36rem;
  margin: 0 1.38rem 0 1.38rem;

  background: #F7F8F9;

  .article_icon {
    width: 2.5rem;
    height: 2.5rem;
  }

  .article_heading {
    color: #172B4D;
    // font-size: 0.87231rem;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

    span {
      color: var(--zunu_primary_color);
      color: #1463FF;
    }
  }

  .article_body {
    color: #626F86;
    // font-size: 0.65425rem;
    font-size: 0.9rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    font-family: "Inter";
  }

  @media screen and (min-width: 730px) {
    gap: 0.5rem;

    // width: 11.435rem;
    // height: 16.58075rem;

    margin: 0 0.75rem;

    padding: 1rem;

    .article_icon {
      width: 1.5rem;
      height: 1.5rem;
    }

    .article_heading {
      font-size: 1.01644rem;
    }

    .article_body {
      font-size: 0.76231rem;
    }
  }

  @media screen and (min-width: 1000px) {
    padding: 1.26rem;
  }

  @media screen and (min-width: 1400px) {
    gap: 0.75rem;

    // width: 19.77538rem;
    // height: 28.67431rem;

    padding: 2rem 2.375rem;

    .article_icon {
      width: 2.5rem;
      height: 2.5rem;
    }

    .article_heading {
      font-size: 1.75781rem;
    }

    .article_body {
      font-size: 1.31838rem;
    }
  }

  @media screen and (min-width: 1700px) {
    gap: 1rem;

    padding: 2rem 2.375rem;

    .article_icon {
      width: 3.5rem;
      height: 3.5rem;
    }

    .article_heading {
      font-size: 2rem;
      line-height: 2.5rem;
    }

    .article_body {
      font-size: 1.5rem;
      line-height: 2.5rem;
    }
  }
}
