@keyframes opacity {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}


@keyframes updown {
  0% {
    transform: translateY(-25%);
  }

  50% {
    transform: translateY(25%);
  }

  100% {
    transform: translateY(-25%);
  }
}