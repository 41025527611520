.privacy_section {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  background: #000;

  color: #ffffff;

  padding: 1.44rem 0 1.31rem 1.25rem;

  h3 {
    color: #fff;
    font-size: 1.625rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .privacy_tabs_section {
    display: flex;
    flex-direction: column;
    gap: 2.4rem;

    .privacy_tabs_container {
      display: flex;
      gap: 1rem;

      padding-left: 1rem;

      .privacy_tabs_name {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        gap: 1rem;

        color: #9c9b9b;
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-transform: capitalize;

        cursor: pointer;

        width: max-content;

        .tabs_border_active {
          display: none;
          width: 140%;
          height: 2px;
          background: #fff;
        }
      }

      .privacy_tabs_name_active {
        color: #ffffff;

        .tabs_border_active {
          display: flex;
        }
      }
    }

    .privacy_contents_container {
      position: relative;

      display: grid;
      place-items: center;

      z-index: 0;

      .privacy_left_arrow,
      .privacy_right_arrow {
        position: absolute;

        box-sizing: border-box;
        padding: 0;
        margin: 0;
        background: none;
        border: none;

        display: flex;
        height: max-content;

        top: 50%;

        z-index: 1000;

        cursor: pointer;
        transform: translateY(-50%);

        svg {
          width: 2.24425rem;
          height: 2.24425rem;
        }
      }

      .privacy_left_arrow {
        left: -0.75rem;
      }

      .privacy_right_arrow {
        right: 0.5rem;
      }

      .privacy_cards_carousel {
        position: initial;
        z-index: 0;

        display: flex;
        gap: 1rem;

        width: 100%;
        height: 14.7rem;

        overflow-x: hidden !important;

        ul {
          height: inherit;
          display: flex;
          gap: 1rem !important;

          user-select: none;

          li {
            list-style-type: none;
            height: inherit;
            animation: slide-in-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
              both;
          }
        }
      }

      @media screen and (min-width: 730px) {
        .privacy_left_arrow,
        .privacy_right_arrow {
          svg {
            width: 2.24425rem;
            height: 2.24425rem;
          }
        }

        .privacy_left_arrow {
          left: -2.25rem;
        }
        .privacy_cards_carousel {
          height: 12.56rem;

          ul {
            gap: 0.87rem;
          }
        }
      }

      @media screen and (min-width: 800px) {
        .privacy_cards_carousel {
          height: 14rem;

          ul {
            gap: 0.87rem;
          }
        }
      }

      @media screen and (min-width: 1000px) {
        .privacy_left_arrow,
        .privacy_right_arrow {
          svg {
            width: 2.75175rem;
            height: 2.75175rem;
          }
        }
        .privacy_left_arrow {
          left: -4.25rem;
        }

        .privacy_cards_carousel {
          height: 17.4rem;

          ul {
            gap: 1.07rem !important;
          }
        }
      }

      @media screen and (min-width: 1400px) {
        .privacy_left_arrow,
        .privacy_right_arrow {
          svg {
            width: 3.875rem;
            height: 3.875rem;
          }
        }

        .privacy_cards_carousel {
          height: 21.6875rem;

          ul {
            gap: 1.5rem !important;
          }
        }
      }

      @media screen and (min-width: 1700px) {
        .privacy_left_arrow,
        .privacy_right_arrow {
          svg {
            width: 4.64238rem;
            height: 4.64238rem;
          }
        }

        .privacy_cards_carousel {
          height: 28rem;

          ul {
            gap: 1.8rem !important;
          }
        }
      }

      @media screen and (min-width: 1900px) {
        .privacy_cards_carousel {
          height: 30rem;

          ul {
            gap: 0.87rem;
          }
        }
      }
    }

    .privacy_policy_button {
      display: flex;
      align-items: center;
      justify-content: center;

      background: none !important;
      border: none !important;
      padding: 0;
      margin: 0;
      margin-top: 4rem;

      height: max-content;

      color: #fff;
      font-size: 0.74963rem;
      font-style: normal;
      font-weight: 500;

      text-decoration: underline;
      text-align: center;

      .privacy_policy_arrow_icon {
        margin-left: 0.5rem;

        width: 0.74963rem;
        height: 0.74963rem;
      }
    }
  }

  @media screen and (min-width: 730px) {
    padding: 1.5rem 0 0.85rem 2.54rem;
    gap: 2.6rem;

    h3 {
      font-size: 1.66513rem;
    }

    .privacy_tabs_section {
      gap: 0.9rem;

      .privacy_tabs_container {
        gap: 1.63rem;

        .privacy_tabs_name {
          font-size: 0.65156rem;
        }
      }

      .privacy_policy_button {
        margin-top: 2.71rem;

        font-size: 0.65156rem;

        .privacy_policy_arrow_icon {
          margin-left: 0.3rem;

          width: 0.62475rem;
          height: 0.62475rem;
        }
      }
    }
  }

  @media screen and (min-width: 1000px) {
    padding: 2.13rem 0 1.08rem 4.26rem;
    gap: 3.2rem;

    h3 {
      font-size: 2.04163rem;
    }

    .privacy_tabs_section {
      gap: 0.9rem;

      .privacy_tabs_container {
        gap: 2rem;

        .privacy_tabs_name {
          font-size: 0.79888rem;
        }
      }

      .privacy_policy_button {
        margin-top: 3.33rem;

        font-size: 0.79888rem;

        .privacy_policy_arrow_icon {
          margin-left: 0.5rem;

          width: 1.0625rem;
          height: 1.0625rem;
        }
      }
    }
  }

  @media screen and (min-width: 1400px) {
    padding: 3rem 0 1.5rem 6rem;
    gap: 4.5rem;

    h3 {
      font-size: 2.875rem;
    }

    .privacy_tabs_section {
      gap: 1.25rem;
      .privacy_tabs_container {
        gap: 2.81rem;

        .privacy_tabs_name {
          font-size: 1.125rem;
        }
      }

      .privacy_policy_button {
        margin-top: 4.7rem;

        font-size: 1.125rem;

        .privacy_policy_arrow_icon {
          margin-left: 0.5rem;

          width: 1.3125rem;
          height: 1.3125rem;
        }
      }
    }
  }

  @media screen and (min-width: 1700px) {
    padding: 3.59rem 0 1.82rem 7.19rem;
    gap: 5.37rem;

    h3 {
      font-size: 3.44438rem;
    }

    .privacy_tabs_section {
      gap: 1.53rem;

      .privacy_tabs_container {
        gap: 3.37rem;

        .privacy_tabs_name {
          font-size: 1.34781rem;
        }
      }

      .privacy_policy_button {
        margin-top: 4.7rem;

        font-size: 1.34781rem;
      }
    }
  }
}

.carousel_item_style {
  width: 10.5rem !important;

  @media screen and (min-width: 730px) {
    width: 9rem !important;
  }

  @media screen and (min-width: 1000px) {
    width: 11rem !important;
  }

  @media screen and (min-width: 1400px) {
    width: 16rem !important;
  }

  @media screen and (min-width: 1700px) {
    width: 18.5rem !important;
  }
}

@keyframes slide-in-right {
  0% {
    transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
