.modalContainer {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 2;
	background: rgba(29, 29, 29, 0.32);
	display: flex;
	justify-content: center;
	align-items: center;
}

.modal {
	padding: 1.3rem;
	background-color: #fff;
	position: relative;
	height: auto;
	width: 85vw;
	z-index: 2;
	border-radius: 1.6rem;
	display: flex;
	flex-direction: column;
	gap: 1.7rem;

	@media screen and (min-width: 730px) {
		padding: 1.3rem;
		width: 35rem;
		gap: 1.7rem;
	}

	@media screen and (min-width: 1000px) {
		padding: 1.6rem;
		width: 35rem;
		gap: 2.2rem;
	}

	@media screen and (min-width: 1400px) {
		padding: 2rem;
		width: 43rem;
		gap: 2.8rem;
	}

	@media screen and (min-width: 1700px) {
		padding: 2.75rem;
		width: 55rem;
		gap: 2.5rem;
	}

	.modalHeader {
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
		font-size: 1rem;

		@media screen and (min-width: 730px) {
			font-size: 1rem;
		}

		@media screen and (min-width: 1000px) {
			font-size: 1.2rem;
		}

		@media screen and (min-width: 1400px) {
			font-size: 1.5rem;
		}

		@media screen and (min-width: 1700px) {
			font-size: 1.5rem;
		}

		p {
			color: #1c1b1f;
			font-weight: 600;
		}

		.closeIcon {
			height: 1.6rem;
			cursor: pointer;

			@media screen and (min-width: 730px) {
				height: 1.7rem;
			}

			@media screen and (min-width: 1000px) {
				height: 2rem;
			}

			@media screen and (min-width: 1400px) {
				height: 2.5rem;
			}

			@media screen and (min-width: 1700px) {
				height: 2.5rem;
			}
		}
	}

	form {
		width: 100%;
		display: flex;
		flex-direction: column;
		gap: 1.3rem;

		@media screen and (min-width: 730px) {
			gap: 1.8rem;
		}

		@media screen and (min-width: 1000px) {
			gap: 2rem;
		}

		@media screen and (min-width: 1400px) {
			gap: 2rem;
		}

		@media screen and (min-width: 1700px) {
			gap: 2.5rem;
		}

		.inputWrapper {
			width: 100%;
			display: flex;
			flex-direction: column;
			gap: .7rem;

			@media screen and (min-width: 730px) {
				gap: .65rem;
			}

			@media screen and (min-width: 1000px) {
				gap: .8rem;
			}

			@media screen and (min-width: 1400px) {
				gap: 1rem;
			}

			@media screen and (min-width: 1700px) {
				gap: 1.2rem;
			}

			.inputHeading {
				color: #444343;
				font-weight: 500;
				font-size: .85rem;

				@media screen and (min-width: 730px) {
					font-size: .9rem;
				}

				@media screen and (min-width: 1000px) {
					font-size: 1rem;
				}

				@media screen and (min-width: 1400px) {
					font-size: 1.3rem;
				}

				@media screen and (min-width: 1700px) {
					font-size: 1.7rem;
				}
			}

			.inputContainer {
				width: 100%;
				border: 1px solid #9c9b9b;
				border-radius: 0.6rem;
				padding: 0.4rem 0.6rem;

				@media screen and (min-width: 730px) {
					padding: 0.5rem 0.8rem;
				}

				@media screen and (min-width: 1000px) {
					padding: .5rem .7rem;
				}

				@media screen and (min-width: 1400px) {
					padding: .6rem .8rem;
				}

				@media screen and (min-width: 1700px) {
					padding: .8rem 1rem;
				}

				input {
					outline: none;
					border: none;
					width: 100%;
					height: 100%;
					font-size: .85rem;

					@media screen and (min-width: 730px) {
						font-size: .9rem;
					}

					@media screen and (min-width: 1000px) {
						font-size: 1rem;
					}

					@media screen and (min-width: 1400px) {
						font-size: 1.3rem;
					}

					@media screen and (min-width: 1700px) {
						font-size: 1.7rem;
					}
				}
			}

			.errorMessage {
				color: red;
				font-size: .85rem;

				@media screen and (min-width: 730px) {
					font-size: .8rem;
				}

				@media screen and (min-width: 1000px) {
					font-size: .9rem;
				}

				@media screen and (min-width: 1400px) {
					font-size: 1rem;
				}

				@media screen and (min-width: 1700px) {
					font-size: 1.3rem;
				}
			}

			.errorBorder {
				border: 1px solid orange;
			}
		}

		.submitBtn {
			display: flex;
			align-self: flex-end;
			border: none;
			outline: none;
			background-color: var(--zunu_primary_color);
			padding: .6rem 1.2rem;
			color: white;
			border-radius: .6rem;
			font-size: .85rem;
			font-weight: 600;
			cursor: pointer;

			@media screen and (min-width: 730px) {
				border-radius: 1rem;
				padding: .8rem 1.3rem;
				font-size: .9rem;
			}

			@media screen and (min-width: 1000px) {
				padding: .9rem 1.5rem;
				font-size: 1rem;
			}

			@media screen and (min-width: 1400px) {
				padding: .8rem 1.3rem;
				font-size: 1.3rem;
			}

			@media screen and (min-width: 1700px) {
				padding: 1rem 1.5rem;
				font-size: 1.7rem;
			}
		}

		.submitBtnDisabled {
			cursor: not-allowed;
			background-color: #3d78efbd;
		}
	}
}
