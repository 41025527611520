:root {
	--theme_color_dark: #0e0d1c;
	--theme_color_light: #fffefe;

	--theme_color_opacity_dark: #0e0d1cbc;
	--theme_color_opacity_light: #fffefec6;

	--text_color1_dark: #fafafa;
	--text_color1_light: #1c1b1f;
	--text_color2_dark: #c8c7cf;

	--logo_color_dark: #ffffff;
	--logo_color_light: #000000;

	--text_color_black: black;
	--text_color_white: white;

	--light_gray_text: #aaa9a9;
	--light_gray_text2: #e1e0e4;
	--dark_gray_text2: #444343;
	--gray_border: rgba(255, 255, 255, 0.46);

	--zunu_primary_color: #4359f6;
	--zunu_secondary_color: #497fef;
	--zunu_shade_1: #4c4ad2;
	--zunu_shade_2: #0065ff;
	--message_primary_color: #38d15a;
	--ziroh_primary_color: #F12A62;

	--gradient_text_dark: linear-gradient(90deg, #6bafff 0%, #59ffc3 100%);
	--gradient_text_light: radial-gradient(
		83.98% 83.97% at 50.27% 27.2%,
		#4c4ad2 0%,
		#5765e4 52.6%,
		#71a0fa 100%
	);
	--gradient_text2_dark: radial-gradient(
		77.63% 77.63% at 49.93% 22.37%,
		#fcd6ff 0%,
		#fffdd7 100%
	);

	--mandala_color_dark: linear-gradient(
		360deg,
		#f1da88 0%,
		rgba(243, 224, 154, 0.84) 15.72%,
		rgba(255, 255, 255, 0.02) 81.77%
	);
	/* --mandala_color_light: linear-gradient(0deg, #F1A788 0%, rgba(243, 181, 154, 0.84) 15.72%, rgba(255, 255, 255, 0.00) 100%); */
	--mandala_color_light: linear-gradient(
		0deg,
		#eb5c1f 0%,
		rgba(195, 80, 31, 0.84) 15.72%,
		rgba(255, 255, 255, 0) 100%
	);

	--border_gradient: linear-gradient(
		180deg,
		#323735 1.04%,
		#9bddc5 44.27%,
		#646766 100%
	);
	--camera_border_gradient: linear-gradient(
		75.08deg,
		#4359f6 -11.7%,
		rgba(122, 162, 243, 0) 93.49%
	);
	--drive_border_gradient: linear-gradient(
		284.34deg,
		#6f98ec -2.79%,
		rgba(122, 162, 243, 0) 109.99%
	);
	--mail_border_gradient: linear-gradient(
		80.59deg,
		rgba(122, 162, 243, 0) -2.73%,
		#5886ff 117.51%
	);
	--message_border_gradient: linear-gradient(
		284.34deg,
		rgba(122, 162, 243, 0) -2.79%,
		#339773 109.99%
	);

	--indian_colors_gradient: linear-gradient(
		85deg,
		#fe891d 0%,
		#0b7109 92.71%
	);
	--pricing_gradient_1: linear-gradient(90deg, #ffaa2b 5.02%, #00b2ff 100%);
	--pricing_gradient_2: linear-gradient(90deg, #4744ff 5.02%, #ff5454 100%);

	--transparent_bg_dark: rgba(49, 49, 67, 0.2);
	--transparent_bg_light: #fafbff;

	--light_stroke: #e8ecfd;
	--dark_sroke: #2f2e5a;

	--backdrop_color: rgba(150, 150, 150, 0.2);

	--dark_bg: #121212;
	--dark_blue_bg: #00073c;
	--light_dark_bg: #313143;
	--light_gray_stroke: #707070;
	--dark_grey_bg: #1c1c1c;
	--light_grey_bg: #f0f0f0;
	--light_white: #dfdfdf;
	--light_black: #202020;
	--light_dark_gray: #9c9b9b;

	--faq_topic_shadow_light: 0px 2px 6px 2px rgba(109, 141, 173, 0.15),
		0px 1px 2px 0px rgba(109, 141, 173, 0.3);
	--faq_topic_shadow_dark: 0px 2px 6px 2px rgba(109, 141, 173, 0.15),
		0px 1px 2px 0px rgba(109, 141, 173, 0.3);

	--apple_icon_bg_light: #252525;
	--apple_icon_bg_dark: #e7e7e7;
	--windows_icon_bg: #e5f6ff;
	--linux_icon_bg: #fff;
	--android_icon_bg: #edf3cd;
	--portable_icon_bg: #4359f6;

	--light_black_shadow: 0px 0px 8.44256px 0px rgba(0, 0, 0, 0.25);
	--light_black_shadow: 0px 0px 8.44256px 0px rgba(0, 0, 0, 0.25);

	--light_gray_shadow: 0px 0px 4px 0px rgba(170, 170, 170, 0.25);

	--radial_bg_light: radial-gradient(
		117.65% 117.65% at 49.57% 44.9%,
		#fff 0%,
		#e6dfe7 100%
	);
	--radial_bg_dark: radial-gradient(
		76.34% 76.34% at 50% 50%,
		#615c77 0%,
		#2f2c3c 84.38%
	);

	--lightBlue: #f9faff;
	--darkBlue: #14132d;

	--page_yoffset: 100vh;

	--things_cards_bg_light: #f9f9f9;
	--things_cards_bg_dark: #252231;

	--no_ads_color: #ffecec;
	--no_cookies_color: #e4f9ff;
	--no_trackers_color: #e5e7ff;

	--border_dashed_dark: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='16' ry='16' stroke='%23FFFFFF6B' stroke-width='4' stroke-dasharray='20%2c 32' stroke-dashoffset='9' stroke-linecap='round'/%3e%3c/svg%3e");
	--border_dashed_light: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='16' ry='16' stroke='%2300000047' stroke-width='4' stroke-dasharray='20%2c 32' stroke-dashoffset='9' stroke-linecap='round'/%3e%3c/svg%3e");

	--nav_height: 3.5rem;
	@media screen and (min-width: 550px) {
		--nav_height: 5rem;
	}
	@media screen and (min-width: 730px) {
		--nav_height: 4rem;
	}

	@media screen and (min-width: 1000px) {
		--nav_height: 4rem;
	}

	@media screen and (min-width: 1400px) {
		--nav_height: 5rem;
	}

	@media screen and (min-width: 1700px) {
		--nav_height: 5rem;
	}
}

.dark {
	--bg_color: var(--theme_color_dark);
	--nav_color: var(--theme_color_opacity_dark);
	--text_color1: var(--text_color1_dark);
	--text_heading: var(--text_color2_dark);
	--logo_color: var(--logo_color_dark);
	--btn_bg_color: var(--theme_color_light);
	--btn_text_color: var(--text_color_black);
	--mandala_design_color: var(--mandala_color_dark);
	--description1_color: var(--light_gray_text);
	--gradient_text: var(--gradient_text_dark);
	--description3_color: var(--text_color1_dark);
	--pricing_card_primary_text_color: var(--text_color_white);
	--pricing_card_secondary_text_color: var(--text_color1_dark);
	--transparent_bg: var(--transparent_bg_dark);
	--pricing_heading: var(--text_color_white);
	--features_text: var(--text_color_white);
	--KeyFeatureBackground: var(--light_dark_bg);
	--sub_text: var(--light_gray_text2);
	--iconWrapperBg: var(--dark_grey_bg);
	--downloadPlatformHeading: var(--light_white);
	--faq_topic_bg: transparent;
	--faq_topic_shadow: (--faq_topic_shadow_dark);
	--pp_text: var(--text_color_white);
	--contact_us_bg: #6091f1;
	--input_border: #4f4f4f;
	--input_bg: #1c1c25;
	--registered_text: var(--text_color2_dark);
	--contact_us_illustration_bg: #1f1d37;
	--contact_us_btn_bg: #5b6ef7;
	--attachment_chip_bg: #242424;
	--things_cards_bg: var(--things_cards_bg_dark);
	--zunu_color: var(--zunu_secondary_color);
	--radial_bg: var(--radial_bg_dark);
	--article_border: var(--gray_border);
	--blue_bg: var(--darkBlue);
	--join_btn_bg: var(--zunu_secondary_color);
	--faq_article_border: var(--dark_stroke);
	--drop_down_bg: #0e0d1c;
	--support_banner_bg: #1f1e46;
	--home_desc: #fff;
	--iconWrapperBorder: #fff;
	--apple_icon_bg: var(--apple_icon_bg_dark);
	--arrow_fill_color: var(--text_color2_dark);
	--dropin_border: var(--border_dashed_dark);
	--dropin_dnd_text: var(--text_color2_dark);
	--dropin_browse_text: var(--text_color1_dark);
	--dropin_bg: rgba(255, 255, 255, 0.03);
	--apple_icon_bg: var(--apple_icon_bg_dark);
	--arrow_fill_color: #c8c7cf;
	--input_optional: #a8a8a8;
	//   DROPIN
	--drop_in_card_text: var(--text_color2_dark);
	--drop_in_card_bg: rgba(255, 255, 255, 0.07);
	--drop_in_scrollbar_track: #4d4f67;
	--drop_in_scrollbar_thumb: #999a9d;
	--drop_in_close_fill: #c8c7cf;
	--drop_in_btn_secondary_bg: rgba(0, 0, 0, 0.55);
	--drop_in_btn_secondary_bs: 0px 1.5px 1.5px 0px rgba(0, 0, 0, 0.15);
	--drop_in_btn_secondary_color: #fff;
	--drop_in_btn_delete_color: #ff6363;
	--drop_in_text_color: var(--text_color_white);
	--transparent_bg_profile: rgba(255, 255, 255, 0.03);
	// MODAL
	--modal_bg: rgba(0, 0, 0, 0.67);
	--modal_color: #242424;
	--modal_shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.84);
	--modal_input_bg: rgba(255, 255, 255, 0.17);
	--modal_inactive_text: #a8a8a8;
	--testimonial_carousel_bg: #000000;
	--testimonial_card_bg: #1c1b1f;
	--testimonial_text_color: #d6d6d6;
	--testimonail_heading_color: #ffffff;
	--testimonial_user_color: #ffffff;
	--modal_divider_line: #4d4f67;
	--otp_sent_text: #fafafa;
	--otp_input_color: #ffffff;
	--otp_input_border: #4d4f67;
}

.light {
	--bg_color: var(--theme_color_light);
	--nav_color: var(--theme_color_opacity_light);
	--text_color1: var(--text_color1_light);
	--logo_color: var(--logo_color_light);
	--btn_bg_color: var(--logo_color_light);
	--btn_text_color: var(--text_color_white);
	--text_heading: var(--text_color1_light);
	--mandala_design_color: var(--mandala_color_light);
	--description1_color: var(--text_color1_light);
	--gradient_text: var(--zunu_primary_color);
	--description3_color: var(--text_color1_light);
	--pricing_card_primary_text_color: var(--text_color_black);
	--pricing_card_secondary_text_color: var(--text_color1_light);
	--transparent_bg: var(--transparent_bg_light);
	--pricing_heading: var(--text_color1_light);
	--features_text: var(--dark_gray_text2);
	--KeyFeatureBackground: var(--logo_color_dark);
	--sub_text: var(--text_color1_light);
	--iconWrapperBg: var(--light_grey_bg);
	--downloadPlatformHeading: var(--light_black);
	--faq_topic_bg: var(--text_color1_dark);
	--faq_topic_shadow: (--faq_topic_shadow_light);
	--pp_text: var(--text_color_black);
	--contact_us_bg: var(--zunu_secondary_color);
	--input_border: #9c9b9b;
	--input_bg: var(--text_color1_dark);
	--registered_text: var(--dark_gray_text2);
	--contact_us_illustration_bg: #e8ecfd;
	--contact_us_btn_bg: #4359f6;
	--attachment_chip_bg: #e8e8e8;
	--things_cards_bg: var(--things_cards_bg_light);
	--zunu_color: var(--zunu_primary_color);
	--radial_bg: var(--radial_bg_light);
	--article_border: var(--zunu_shade_1);
	--blue_bg: var(--lightBlue);
	--join_btn_bg: var(--text_color_black);
	--faq_article_border: var(--light_stroke);
	--drop_down_bg: #fff;
	--support_banner_bg: var(--light_stroke);
	--home_desc: #5b5b5b;
	--iconWrapperBorder: #fff;
	--apple_icon_bg: var(--apple_icon_bg_light);
	--home_desc: #5b5b5b;
	--arrow_fill_color: #dbdbdb;
	--dropin_border: var(--border_dashed_light);
	--dropin_dnd_text: var(--dark_gray_text2);
	--dropin_browse_text: var(--zunu_shade_2);
	--dropin_bg: rgba(255, 255, 255, 0.16);
	--apple_icon_bg: var(--apple_icon_bg_light);
	--arrow_fill_color: #dbdbdb;
	--input_optional: #5b5b5b;
	//   DROPIN
	--drop_in_card_text: var(--dark_gray_text2);
	--drop_in_card_bg: rgba(255, 255, 255, 0.21);
	--drop_in_scrollbar_track: #b9b9b9;
	--drop_in_scrollbar_thumb: #6a6a6a;
	--drop_in_close_fill: #5b5b5b;
	--drop_in_btn_secondary_bg: rgba(255, 255, 255, 0.74);
	--drop_in_btn_secondary_bs: 0px 1.5px 1.5px 0px rgba(0, 0, 0, 0.15);
	--drop_in_btn_secondary_color: #2b7fff;
	--drop_in_btn_delete_color: #ff6363;
	--drop_in_text_color: var(--dark_gray_text2);
	--transparent_bg_profile: rgba(0, 0, 0, 0.05);
	//TESTIMONIAL
	--testimonial_carousel_bg: #f4f4f4;
	--testimonial_card_bg: #ffffff;
	--testimonial_text_color: #444343;
	--testimonail_heading_color: #1c1b1f;
	--testimonial_user_color: #444343;
	// MODAL
	--modal_bg: rgba(0, 0, 0, 0.24);
	--modal_color: #ffffff;
	--modal_shadow: 0px 0px 18px 2px rgba(0, 0, 0, 0.1);
	--modal_input_bg: rgba(0, 0, 0, 0.06);
	--modal_inactive_text: #9c9b9b;
	--modal_shadow: 0px 0px 18px 2px rgba(0, 0, 0, 0.1);
	--modal_divider_line: #cfcfd4;
	--otp_sent_text: #444343;
	--otp_input_color: #444343;
	--otp_input_border: #4d4f67;
}

* {
	transition: all 200ms ease-in-out;
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

html {
	width: 100%;
	display: flex;
	justify-content: center;
	background-color: var(--bg_color);
}

body {
	width: 100%;
	line-height: normal;
	overflow-x: hidden;
	max-width: 2500px;
}

a {
	text-decoration: none;
}

li{
	list-style-type: disc;
}

.PhoneInput {
	width: 100%;
}

