.faqContainer {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	background-image: url(../../../../assets/webp/mail_faq_bg.webp);
	background-repeat: no-repeat;
	background-position: bottom;
	background-size: 100%;
	gap: 0.8rem;
	padding: 1.5rem 2rem;
	

	@media screen and (min-width: 400px) {
		padding: 1.5rem 3.3rem;
	}

	@media screen and (min-width: 550px) {
		padding: 1.5rem 5.5rem;
	}

	@media screen and (min-width: 730px) {
		gap: 1.5rem;
		padding: 3rem 10.5rem;
	}

	@media screen and (min-width: 1000px) {
		gap: 2rem;
		padding: 3.8rem 16rem;
	}
	
	@media screen and (min-width: 1400px) {
		gap: 2.5rem;
		padding: 5.3rem 20rem;
	}

	@media screen and (min-width: 1700px) {
		gap: 3.4rem;
		padding: 7rem 35rem;
	}

	.faqHeading {
		font-size: 1.5rem;
		color: #172B4D;
		line-height: 2.125rem;
		font-weight: 700;
		text-align: center;
	
		@media screen and (min-width: 730px) {
			font-size: 2.23rem;
			line-height: 3.156rem;
		}
		@media screen and (min-width: 1000px) {
			font-size: 2.75rem;
			line-height: 3.875rem;
			
		}
		@media screen and (min-width: 1400px) {
			
		}
		@media screen and (min-width: 1700px) {
			
		}
	}
}

.faqWrapper{
    width: 100%;
    border-radius: 1rem;
    background: #ffffff;
    display: flex;
    flex-direction: column;
    // align-items: center;
    padding: 1rem;

    @media screen and (min-width: 400px) {
        padding: 1rem 1.5rem;
    }

    @media screen and (min-width: 550px) {
        padding: 2rem;
    }

    @media screen and (min-width: 730px) {
        padding: 1.8rem 2rem;
    }

    @media screen and (min-width: 1000px) {
        padding: 2.2rem 2rem;
    }
   
    @media screen and (min-width: 1400px) {
        padding: 2rem 2rem;
    }

    @media screen and (min-width: 1700px) {
        padding: 2rem 4rem;
    }

	.faqMore{
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		text-decoration: underline;
		color: var(--zunu_primary_color);
		margin-top: 1rem;
		gap: .5rem;
		font-weight: 600;
		font-size: .8rem;

		@media screen and (min-width: 730px) {
			margin-top: 1.8rem;
			
		}
	
		@media screen and (min-width: 1000px) {
			margin-top: 2.2rem;
			font-size: .9rem;
		}
	   
		@media screen and (min-width: 1400px) {
			margin-top: 3rem;
			font-size: 1rem;
		}
	
		@media screen and (min-width: 1700px) {
			margin-top: 4rem;
		}

		.arrow{
			height: 1.2rem;
		}
	}
}