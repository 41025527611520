.portablePageContainer {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 2rem;

	@media screen and (min-width: 730px) {
		gap: 2.61rem
	}

	@media screen and (min-width: 1000px) {
		gap: 3.2rem;
	}

	@media screen and (min-width: 1400px) {
		gap: 4.5rem;
	}

	@media screen and (min-width: 1700px) {
		gap: 6rem;
	}

}

.portablePageHeader {
	display: flex;
	font-size: 3.25rem;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	background: linear-gradient(180deg, #FFF 0%, #EDF1FF 100%);
	padding-top: 3rem;
	gap: 2rem;

	figure {
		display: flex;
		align-items: flex-end;
		width: 100%;
		height: 100%;
	}

	.headerMain {
		display: flex;
		flex-direction: column;
		justify-content: center;
		gap: .81rem;

		@media screen and (min-width: 730px) {
			gap: .81rem;
		}

		@media screen and (min-width: 1000px) {
			gap: 1rem;
		}

		@media screen and (min-width: 1400px) {
			gap: 1.36rem;
		}

		@media screen and (min-width: 1700px) {
			gap: 1.81rem;
		}

	}

	.pageheader {
		display: flex;
		font-size: 1.5rem;
		font-weight: 700;
		color: #1c1b1f;
		gap: .5rem;
		text-align: center;
		justify-content: center;
		width: 100%;

		@media screen and (min-width: 730px) {
			font-size: 1.41rem;
			gap: 0.285rem;
		}

		@media screen and (min-width: 1000px) {
			font-size: 1.73rem;
			gap: 0.34588rem;
		}

		@media screen and (min-width: 1400px) {
			font-size: 2.47rem;
			gap: .54rem
		}

		@media screen and (min-width: 1700px) {
			font-size: 3.25rem;
			gap: .75rem;
		}

		span {
			width: 45%;
			text-align: left;
			color: var(--zunu_primary_color);

		}
	}

	.portableSubheader {
		font-size: 1.41rem;
		font-weight: 700;
		color: #1c1b1f;

		span {
			color: #9C9B9B;
		}

		@media screen and (min-width: 730px) {
			font-size: 1.41rem;
		}

		@media screen and (min-width: 1000px) {
			font-size: 1.73rem;
		}

		@media screen and (min-width: 1400px) {
			font-size: 2.47rem;
		}

		@media screen and (min-width: 1700px) {
			font-size: 3.25rem;
		}
	}

	.macImage {
		display: flex;
		justify-content: center;
		padding: 0 1.6rem;

		@media screen and (min-width: 730px) {
			padding: 0 10.5rem;
		}

		@media screen and (min-width: 1000px) {
			padding: 0 13rem;
		}

		@media screen and (min-width: 1400px) {
			padding: 0 18rem;
		}

		@media screen and (min-width: 1700px) {
			padding: 0 24rem;
		}

	}
}

.portableMain {
	display: flex;
	width: 100%;
	justify-content: center;
	align-items: center;
	padding: 1.72rem .90rem;
	background: #F2F3FF;

	@media screen and (min-width: 730px) {
		padding: 2.225rem 7.4115rem;
	}

	@media screen and (min-width: 1000px) {
		padding: 2.74rem 9.1rem;
	}

	@media screen and (min-width: 1400px) {
		padding: 3.78125rem 12.79688rem;
	}

	@media screen and (min-width: 1700px) {
		padding: 5.0625rem 17.0625rem;
	}

}

.privacyAndSecurityContent {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 2rem;

	@media screen and (min-width: 730px) {
		gap: 1.125rem;
	}

	@media screen and (min-width: 1000px) {
		gap: 1.4rem;
	}

	@media screen and (min-width: 1400px) {
		gap: 1.96875rem;
	}

	@media screen and (min-width: 1700px) {
		gap: 2.62rem;
	}

}

.privacyContainer {
	display: flex;
	flex-direction: column;

	.privacyHeaderDesktop {
		color: #1c1b1f;
		font-size: 1.5rem;
		font-weight: 700;
		text-align: center;
		width: 100%;

		@media screen and (min-width: 730px) {
			font-size: 1.411rem;
		}

		@media screen and (min-width: 1000px) {
			font-size: 1.733rem;
		}

		@media screen and (min-width: 1400px) {
			font-size: 2.43rem;
		}

		@media screen and (min-width: 1700px) {
			font-size: 3.25rem;
		}
	}

	span {
		color: var(--zunu_primary_color)
	}

}

.privacyContents {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-template-rows: auto;
	gap: 1.5rem;

	@media screen and (min-width: 730px) {
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		gap: 1.14rem;
		border-radius: 1.14025rem;
	}

	@media screen and (min-width: 1000px) {
		gap: 1.4rem;
		border-radius: 1.4rem;
	}

	@media screen and (min-width: 1400px) {
		gap: 1.96875rem;
		border-radius: 1.96875rem;

	}

	@media screen and (min-width: 1700px) {
		gap: 2.62rem;
		border-radius: 2.625rem;
	}


	.privacyContentCards {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: .57rem;
		text-align: center;
		padding: 1.14113rem 0.85588rem;
		background: none;
		font-size: .875rem;
		font-weight: 500;

		@media screen and (min-width: 320px) {
			font-size: .875rem;
			border-radius: 1.49775rem;
			background: #FFF;
			box-shadow: -0.571px 6.847px 14.264px 0px rgba(194, 194, 194, 0.10), -1.712px 26.246px 26.246px 0px rgba(194, 194, 194, 0.09), -3.994px 59.339px 35.375px 0px rgba(194, 194, 194, 0.05), -7.417px 105.556px 42.222px 0px rgba(194, 194, 194, 0.01), -11.411px 164.324px 46.216px 0px rgba(194, 194, 194, 0.00);
			padding: 1.14113rem 0.85588rem;
			gap: 0.57rem;

			svg {
				width: 2.28231rem;
				height: 2.28231rem;
			}
		}

		@media screen and (min-width: 730px) {
			background: none;
			box-shadow: none;
			gap: 0.43rem;
			font-size: 0.75rem;
			padding: 0.86875rem 0.65156rem;

			svg {
				width: 1.7375rem;
				height: 1.7375rem;
			}

		}

		@media screen and (min-width: 1000px) {
			gap: 0.53rem;
			font-size: 0.8rem;
			padding: 1.06669rem 0.8rem;

			svg {
				width: 2.13331rem;
				height: 2.13331rem;
			}

		}

		@media screen and (min-width: 1400px) {
			gap: 0.75rem;
			font-size: 1.125rem;
			padding: 1.5rem;

			svg {
				width: 3rem;
				height: 3rem;
			}

		}

		@media screen and (min-width: 1700px) {
			gap: 1rem;
			font-size: 1.5rem;
			padding: 2rem 1.5rem;

			svg {
				width: 4rem;
				height: 4rem;
			}
		}

	}

}

.privacyContentCards:hover {
	background: #FFF;
	box-shadow: -1px 12px 25px 0px rgba(194, 194, 194, 0.10), -3px 46px 46px 0px rgba(194, 194, 194, 0.09), -7px 104px 62px 0px rgba(194, 194, 194, 0.05), -13px 185px 74px 0px rgba(194, 194, 194, 0.01), -20px 288px 81px 0px rgba(194, 194, 194, 0.00);

}


.zunuPortableContents {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 2rem;
	padding: 0 1.7rem 1.7rem 1.7rem;

	@media screen and (min-width: 730px) {
		gap: 2.61rem;
		padding: 0 3.91rem 4rem 3.9rem;
	}

	@media screen and (min-width: 1000px) {
		gap: 3.2rem;
		padding: 0 4.8rem 5rem 4.8rem;
	}

	@media screen and (min-width: 1400px) {
		gap: 4.5rem;
		padding: 0 6.75rem 7rem 6.75rem;
	}

	@media screen and (min-width: 1700px) {
		gap: 6rem;
		padding: 0 9rem 10rem 9rem;
	}

	p {
		font-size: 1.47rem;
		font-style: normal;
		font-weight: 700;
		text-align: center;

		@media screen and (min-width: 730px) {
			font-size: 1.41rem;
		}

		@media screen and (min-width: 1000px) {
			font-size: 1.733rem;
		}

		@media screen and (min-width: 1400px) {
			font-size: 2.43rem;
		}

		@media screen and (min-width: 1700px) {
			font-size: 3.25rem;
		}
	}

	span {
		color: var(--zunu_primary_color)
	}
}

.portableContainer {
	display: flex;
	flex-direction: column;
	width: 95%;
	gap: 3.75rem;

	@media screen and (min-width: 730px) {
		gap: 3.75rem;
	}

	@media screen and (min-width: 730px) {
		gap: 3.37rem;
	}

	@media screen and (min-width: 1000px) {
		gap: 4.13rem;
	}

	@media screen and (min-width: 1400px) {
		gap: 5.81rem;
	}

	@media screen and (min-width: 1700px) {
		gap: 7.75rem;
	}
}

.imgTextContainer {
	display: flex;
	align-items: center;
	flex-direction: column-reverse;
	gap: 3.4rem;
	height: 100%;

	@media screen and (min-width: 730px) {
		flex-direction: row;
		gap: 3.25rem;
	}

	@media screen and (min-width: 1000px) {
		gap: 4rem;
	}

	@media screen and (min-width: 1400px) {
		gap: 5.625rem;
	}

	@media screen and (min-width: 1700px) {
		gap: 7.5rem;
	}
}

.imgTextContainer:nth-child(even) {
	flex-direction: column-reverse;

	@media screen and (min-width: 730px) {
		flex-direction: row-reverse;
	}
}

.textContents {
	display: flex;
	flex-direction: column;
	width: 90%;
	gap: .6rem;

	@media screen and (min-width: 730px) {
		width: 70%;
		gap: 0.625rem;
	}

	@media screen and (min-width: 1000px) {
		gap: 0.8rem;
	}

	@media screen and (min-width: 1400px) {
		gap: 1.125rem;
	}

	@media screen and (min-width: 1700px) {
		gap: 2rem;
	}

	.textContentsHeader {
		text-align: left;
		font-size: 1.125rem;
		font-style: normal;
		font-weight: 700;
		line-height: normal;

		@media screen and (min-width: 730px) {
			font-size: 1.125rem;
		}

		@media screen and (min-width: 1000px) {
			font-size: 1.5rem;
		}

		@media screen and (min-width: 1400px) {
			font-size: 2rem;
		}

		@media screen and (min-width: 1700px) {
			font-size: 2.625rem;
		}

	}

	.textLists {
		display: flex;
		flex-direction: column;
	}

	ul {
		margin-left: 1rem;

		li {
			font-size: .75rem;
			font-weight: 500;

			@media screen and (min-width: 730px) {
				font-size: 0.75rem;
			}

			@media screen and (min-width: 1000px) {
				font-size: .875rem;
			}

			@media screen and (min-width: 1400px) {
				font-size: 1.25rem;
			}

			@media screen and (min-width: 1700px) {
				font-size: 1.5rem;
			}

		}
	}

}

.ImageContent {
	display: flex;
	width: 100%;

	figure {
		height: 100%;
		width: 100%;
	}

}