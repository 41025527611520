.privacy_cards_container {
  height: inherit;
  width: 10.5rem;
  position: relative;
  z-index: 0 !important;
  border-radius: 1rem;
  overflow: hidden;

  .privacy_cards_backdrop {
    position: absolute;
    bottom: 0;
    right: 0;

    filter: blur(45px);
    z-index: 10 !important;
  }

  .privacy_cards {
    position: relative;
    z-index: 100 !important;
    border-radius: 1rem;
    background: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(27.5px);

    height: inherit;
    width: inherit;

    padding: 1.36rem;

    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    p {
      font-size: 0.76431rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }

    .privacy_cards_icon {
      width: 1.35881rem;
      height: 1.35881rem;
    }
  }

  @media screen and (min-width: 730px) {
    width: 9rem !important;

    .privacy_cards {
      padding: 1.16rem;

      p {
        font-size: 0.65156rem;
      }

      .privacy_cards_icon {
        width: 1.15831rem;
        height: 1.15831rem;
      }
    }
  }

  @media screen and (min-width: 1000px) {
    width: 11rem !important;

    .privacy_cards {
      padding: 1.42rem;

      p {
        font-size: 0.79888rem;
      }

      .privacy_cards_icon {
        width: 1.42025rem;
        height: 1.42025rem;
      }
    }
  }

  @media screen and (min-width: 1400px) {
    width: 16rem !important;

    .privacy_cards {
      padding: 2rem;

      p {
        font-size: 1.125rem;
      }

      .privacy_cards_icon {
        width: 2rem;
        height: 2rem;
      }
    }
  }

  @media screen and (min-width: 1700px) {
    width: 18.5rem !important;

    .privacy_cards {
      padding: 2.4rem;

      p {
        font-size: 1.34781rem;
      }

      .privacy_cards_icon {
        width: 2.39606rem;
        height: 2.39606rem;
      }
    }
  }
}
