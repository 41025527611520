@import '../../styles/abstracts';

.homeContainer {
	width: 100%;
	max-width: 2500px;
	display: flex;
	flex-direction: column;
	align-items: center;
	// border: 1px solid red;
	overflow: hidden;
	position: relative;
	font-family: 'Plus Jakarta Sans';
}

.headerContainer {
	width: 100%;
	display: flex;
	flex-direction: column;
	// justify-content: center;
	align-items: center;
	position: relative;
	overflow: hidden;
	z-index: 2;
	padding: 0 0.94rem;
	height: auto;
	background: url(../../assets//webp/home_page_header_bg.webp);
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	// min-height: 500px;
	// max-height: 1100px;
	// height: calc(100vh - var(--nav_height) - 35vh);

	// @media screen and (min-width: 550px) {
	// 	padding: 0 2rem 0 2rem;
	// 	// height: calc(100vh - var(--nav_height) - 50vh);
	// }

	@media screen and (min-width: 730px) {
		min-height: 300px;
		// max-height: 480px;
		// height: calc(100vh - var(--nav_height) - 65vh);
		padding: 0 3rem 0 3rem;
	}

	@media screen and (min-width: 1000px) {
		// height: calc(100vh - var(--nav_height) - 45vh);
	}

	@media screen and (min-width: 1400px) {
		// max-height: 1000px;
		// height: calc(100vh - var(--nav_height) - 35vh);
		padding: 0 14.06rem;
	}

	@media screen and (min-width: 1700px) {
		padding: 0 18.75rem;
	}

	.bgCircle {
		z-index: 1;
		border-radius: 50%;
		position: absolute;
		background-color: #c7daff;
		filter: blur(114.92823028564453px);
		top: -1.5rem;
		right: -5%;
		width: 19rem;
		height: 19rem;

		@media screen and (min-width: 730px) {
			top: -60%;
			right: -25%;
			width: 28.5rem;
			height: 28.5rem;
			filter: blur(173.75001525878906px);
		}

		@media screen and (min-width: 1000px) {
			top: -53%;
			right: -20%;
			width: 35rem;
			height: 35rem;
			filter: blur(213.33334350585938px);
		}

		@media screen and (min-width: 1200px) {
		}

		@media screen and (min-width: 1400px) {
			top: -80%;
			right: -20%;
			width: 50rem;
			height: 50rem;
			filter: blur(300px);
		}

		@media screen and (min-width: 1700px) {
			top: -60%;
			right: -16%;
			width: 66rem;
			height: 66rem;
			filter: blur(400px);
		}
	}
}

.header {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	z-index: 2;
	gap: 2rem;
	margin-bottom: 2.8rem;

	@media screen and (min-width: 550px) {
		gap: 2.06rem;
		margin-bottom: 4.06rem;
	}

	@media screen and (min-width: 1000px) {
		gap: 2.53rem;
		margin-bottom: 5.05rem;
	}

	@media screen and (min-width: 1400px) {
		gap: 2.2rem;
		margin-bottom: 4rem;
	}

	@media screen and (min-width: 1700px) {
		gap: 2.8rem;
		margin-bottom: 3rem;
	}
}

@keyframes rotate {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(350deg);
	}
}

.heading {
	width: 100%;
	// color: var(--text_color1);
	color: #172b4d;
	// background: var(--mandala_design_color);
	font-weight: 700;
	// line-height: 3rem;
	font-size: 2rem;
	text-align: center;
	// padding-top: 2rem;
	padding-top: 5rem;
	user-select: none;
	line-height: 2.5rem;

	span {
		background: linear-gradient(90deg, #2366f6 30%, #f28366 95.83%);
		background-clip: text;
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
	}

	@media screen and (min-width: 730px) {
		font-size: 2.74rem;
		padding-top: 3.39rem;
		line-height: 4.31rem;
	}

	@media screen and (min-width: 1000px) {
		font-size: 3.375rem;
		padding-top: 4.26rem;
		line-height: 5.29rem;
	}

	@media screen and (min-width: 1400px) {
		// font-size: 4.5rem;
	}

	@media screen and (min-width: 1700px) {
		font-size: 4.5rem;
		padding-top: 5.69rem;
	}
}

.zunuProductsContainer {
	width: auto;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	// background-color: #f5f8ff;
	backdrop-filter: blur(3px);
	// padding: 0.5rem 1rem;
	border-radius: 0.85rem;
	// border: 1px solid white ;
	gap: 1rem;

	// @media screen and (min-width: 550px) {
	// 	gap: .5rem;
	// }

	@media screen and (min-width: 730px) {
		border: none;
		background: none;
		backdrop-filter: none;
		gap: 0.61rem;
		border-radius: 0.5rem;
		// padding: 0rem;
	}

	@media screen and (min-width: 1000px) {
		border-radius: 0.6rem;
		// padding: 0.5rem;
	}

	@media screen and (min-width: 1400px) {
		border-radius: 0.8rem;
		// padding: 0.75rem;
	}

	@media screen and (min-width: 1700px) {
		border-radius: 1.125rem;
		// padding: 1rem;
	}

	.productsWrapper {
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 0.75rem;
		padding: 0.75rem;
		border: 2px solid #dde9ff;
		background: #f5f8ff;
		border-radius: 1.125rem;

		// @media screen and (min-width: 400px) {
		// 	gap: 2.5rem;
		// }

		// @media screen and (min-width: 550px) {
		// 	gap: 0.9rem;
		// }

		@media screen and (min-width: 730px) {
			padding: 0.61rem 0.92rem;
			border-radius: 0.5rem;
			border: 1.22px solid #dde9ff;
			// gap: .45rem;;
			// background-color: #F5F8FF;
			// gap: 0.9rem;
		}

		@media screen and (min-width: 1000px) {
			padding: 0.75rem 1.125rem;
			border-radius: 0.75rem;
			gap: 1.89rem;
		}

		@media screen and (min-width: 1400px) {
			padding: 0.75rem 1.125rem;
			border-radius: 0.85rem;
			gap: 1.89rem;
		}

		@media screen and (min-width: 1700px) {
			padding: 1rem 1.5rem;
			border-radius: 1.125rem;
			gap: 2.5rem;
		}

		.seperator {
			position: relative;
		}

		hr {
			// @media screen and (min-width: 730px) {
			width: 1px;
			min-height: 25px;
			height: 70%;
			border: 0;
			border-right: 1px solid #c7daff;
		}
		// }
	}

	.productsContainerHeading {
		font-size: 0.8rem;
		// color: var(--zunu_primary_color);
		color: #1463ff;
		font-weight: 600;
		line-height: normal;
		position: relative;

		@media screen and (min-width: 550px) {
			font-size: 0.7rem;
			// padding: 0.6rem 0.9rem;
		}

		@media screen and (min-width: 730px) {
			color: #0e46b5;
		}

		@media screen and (min-width: 1000px) {
			font-size: 0.8rem;
			// padding: 0.73rem 1rem;
		}

		@media screen and (min-width: 1400px) {
			font-size: 1.12rem;
			// padding: 1rem 1.5rem;
		}

		@media screen and (min-width: 1700px) {
			font-size: 1.5rem;
			padding: 1.4rem 2rem;
		}
	}

	.seperator::before {
		@media screen and (min-width: 730px) {
			content: '';
			position: absolute;
			width: 1px;
			height: 70%;
			background: #c7daff;
			right: 100%;
			top: 15%;
		}
	}

	// .productsContainerHeading::after {
	// 	@media screen and (min-width: 730px) {
	// 		content: '';
	// 		position: absolute;
	// 		width: 1px;
	// 		height: 70%;
	// 		background: #c7daff;
	// 		left: 100%;
	// 		top: 15%;
	// 	}
	// }

	.product {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 0.75rem;
		border-radius: 0.8rem;
		padding: 0.25rem;

		@media screen and (min-width: 730px) {
			flex-direction: row;
			padding: 0.61rem;
			gap: 0.45rem;
		}

		@media screen and (min-width: 1000px) {
			padding: 0.5rem 0.8rem;
			gap: 0.562rem;
		}

		@media screen and (min-width: 1400px) {
			padding: 0.75rem;
			gap: 0.562rem;
		}

		@media screen and (min-width: 1700px) {
			padding: 1rem;
			gap: 0.75rem;
		}

		.productIcon {
			// width: 100%;
			height: 2.625rem;

			@media screen and (min-width: 730px) {
				width: 1.7rem;
			}

			@media screen and (min-width: 1000px) {
				width: 1.6rem;
			}

			@media screen and (min-width: 1400px) {
				width: 2.3rem;
			}

			@media screen and (min-width: 1700px) {
				width: 3rem;
			}

			// svg {
			// 	width: 100%;
			// }
		}

		.productName {
			// color: var(--dark_gray_text2);
			color: #2c3e5d;
			font-size: 1rem;
			text-align: center;
			font-weight: 600;
			line-height: 1.75rem;
			font-family: 'Plus Jakarta Sans';
			// font-family: "Inter";

			@media screen and (min-width: 730px) {
				font-size: 0.916rem;
				line-height: 1.069rem;
			}

			@media screen and (min-width: 1000px) {
				font-size: 1.125rem;
				line-height: 1.31rem;
			}

			@media screen and (min-width: 1400px) {
				font-size: 1.125rem;
			}

			@media screen and (min-width: 1700px) {
				font-size: 1.5rem;
				line-height: 1.75rem;
			}
		}
	}
}

.product:hover {
	transform: scale(1.05);
	transform-origin: center;
	background: #4358f62b;
}

.description {
	color: var(--home_desc);
	text-align: center;
	font-size: 0.875rem;
	font-style: normal;
	font-weight: 500;
	line-height: 1.25rem;
	font-family: 'Inter';

	@media screen and (min-width: 730px) {
		width: 85%;
		font-size: 0.91rem;
		line-height: 1.52rem;
	}

	@media screen and (min-width: 1000px) {
		width: 70%;
		font-size: 1.125rem;
		line-height: 1.875rem;
	}

	@media screen and (min-width: 1400px) {
		width: 75%;
		font-size: 1.125rem;
		line-height: 1.875rem;
	}

	@media screen and (min-width: 1700px) {
		width: 70%;
		font-size: 1.5rem;
		line-height: 2.5rem;
	}
}
.homePageSectionContainer {
	display: flex;
	flex-direction: column;
	width: 100%;
	gap: 2.38rem;

	@media screen and (min-width: 730px) {
		gap: 3.26rem;
	}

	@media screen and (min-width: 1000px) {
		gap: 4rem;
	}

	@media screen and (min-width: 1400px) {
		gap: 6rem;
	}

	@media screen and (min-width: 1700px) {
	}
}
.offerContainer {
	width: 100%;
	background: #1463ff;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	position: relative;

	@media screen and (min-width: 550px) {
	}

	@media screen and (min-width: 730px) {
	}

	@media screen and (min-width: 1000px) {
	}

	@media screen and (min-width: 1200px) {
	}

	@media screen and (min-width: 1400px) {
	}

	@media screen and (min-width: 1700px) {
	}

	.scrollArrow {
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
		cursor: pointer;
		top: 0.75rem;
		height: 1.5rem;
		animation: updown 1s ease infinite;
	}

	.offerTextContainer {
		display: flex;
		align-items: center;
		justify-content: center;

		@media screen and (min-width: 730px) {
			justify-content: flex-start;
		}

		.offerText {
			text-align: center;
			font-weight: 700;
			max-width: 80%;
			color: #f5f8ff;
			padding: 2.5rem 0;
			font-size: 1.5rem;

			@media screen and (min-width: 730px) {
				text-wrap: nowrap;
				padding: 3rem 0;
				font-size: 1.67rem;
				line-height: 2.367rem;
			}

			@media screen and (min-width: 1000px) {
				max-width: 80%;
				padding: 2.2rem 0;
				font-size: 2.06rem;
				line-height: 2.98rem;
			}

			@media screen and (min-width: 1400px) {
				padding: 3rem 0;
				font-size: 3rem;
				line-height: 4.5rem;
			}

			@media screen and (min-width: 1700px) {
				padding: 3rem 0;
				font-size: 4rem;
				line-height: 6rem;
			}
		}
	}
}

.floater {
	position: fixed;
	bottom: calc(env(safe-area-inset-bottom) + 30px);
	margin: auto;
	display: none;
	align-items: center;
	gap: 1.25rem;
	padding: 1.25rem;
	border-radius: 50rem;
	background: var(--bg_color);
	background: black;
	z-index: 5;
	box-shadow: var(--light_gray_shadow);

	@media screen and (min-width: 1200px) {
		display: flex;
	}

	p {
		color: var(--pricing_card_primary_text_color);
		color: #fff;
		font-size: 1.5rem;
		font-weight: 600;
		white-space: nowrap;
	}

	.floaterButton {
		width: 10rem;
	}
}

.downlodButtonContainer {
	width: 8.5rem;
	position: fixed;
	transform: translateY(var(--page_yoffset));
	-webkit-transform: translateY(var(--page_yoffset));
	// top: var(--page_yoffset);
	//   transition: transform 300ms cubic-bezier(.455,.03,.515,.955);
	transition: all 300ms ease-in;
	-moz-transition: all 300ms ease-in; /* Firefox 4 */
	-webkit-transition: all 300ms ease-in; /* Safari and Chrome */
	-o-transition: all 300ms ease-in; /* Opera */
	-ms-transition: all 300ms ease-in; /* Explorer 10 */
	transition-delay: 0;
	z-index: 3;
	display: none;

	@supports (-webkit-appearance: none) and (stroke-color: transparent) {
		transition: all 0.8s;
	}

	@media screen and (min-width: 550px) {
	}

	@media screen and (min-width: 730px) {
		width: 9rem;
	}

	@media screen and (min-width: 1200px) {
		position: relative;
		transform: translateY(0);
		display: block;
	}

	@media screen and (min-width: 1400px) {
		// position: fixed;
		width: 12.5rem;
		// bottom: calc(env(safe-area-inset-bottom) + 30px);
	}

	@media screen and (min-width: 1500px) {
	}

	@media screen and (min-width: 1700px) {
		width: 14rem;
	}
}

.testimonials_container {
	width: 100%;
	margin: 3rem 0 7rem 0;
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 1.5rem;

	@media screen and (min-width: 550px) {
	}
	@media screen and (min-width: 730px) {
		margin: 3rem 0 3rem 0;
		gap: 2.3rem;
	}
	@media screen and (min-width: 1000px) {
		margin: 5.7rem 0 5.5rem 0;
		gap: 2.8rem;
	}
	@media screen and (min-width: 1400px) {
		margin: 5rem 0 7rem 0;
		gap: 3rem;
	}
	@media screen and (min-width: 1700px) {
		margin: 9rem 0 8rem 0;
		gap: 5rem;
	}
}

.testimonails_heading {
	text-align: left;
	font-size: 1.5rem;
	font-weight: 700;
	padding: 0 2rem;
	color: var(--testimonail_heading_color);

	@media screen and (min-width: 550px) {
	}
	@media screen and (min-width: 730px) {
		text-align: center;
		font-size: 2.5rem;
	}
	@media screen and (min-width: 1000px) {
		font-size: 2.8rem;
	}
	@media screen and (min-width: 1400px) {
		font-size: 3rem;
	}
	@media screen and (min-width: 1700px) {
		font-size: 3.5rem;
	}
}

.videoConatiner {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 0 1.06rem;

	@media screen and (min-width: 730px) {
		padding: 0 2.34rem;
	}
	@media screen and (min-width: 1000px) {
		padding: 0 2.88rem;
	}
	@media screen and (min-width: 1400px) {
		padding: 0 10.5rem;
	}
	@media screen and (min-width: 1700px) {
		padding: 0 18.75rem;
	}
}

.mobile {
	display: block;
	// background-color: red;

	@media screen and (min-width: 1200px) {
		display: none;
	}
}
