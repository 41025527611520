.things_zunu_does_section {
  display: flex;
  flex-direction: column;
  gap: 2.31rem;

  padding: 4rem 1rem;

  .things_zunu_does_header {
    width: 90%;
    .things_zunu_does_title {
      color: var(--logo_color);
      font-size: 1.5rem;
      font-style: normal;
      font-weight: 600;

      span {
        color: #4359f6;
      }
    }
  }

  @media screen and (min-width: 730px) {
    align-items: center;
    justify-content: center;
    gap: 3.35rem;

    padding: 3.15rem 1.38rem;

    .things_zunu_does_header {
      width: 80%;
      .things_zunu_does_title {
        font-size: 2.05913rem;
        text-align: center;
      }
    }
  }

  @media screen and (min-width: 1000px) {
  }

  @media screen and (min-width: 1400px) {
    padding: 6.68rem 4.31rem;
    gap: 5.31rem;

    .things_zunu_does_header {
      width: 80%;
      .things_zunu_does_title {
        font-size: 3.3895rem;
      }
    }
  }

  @media screen and (min-width: 1700px) {
    padding: 7rem 6rem;

    .things_zunu_does_header {
      width: 70%;
      .things_zunu_does_title {
        font-size: 4rem;
      }
    }
  }
}
