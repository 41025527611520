@mixin calcContainerWidth($gap, $width, $multiples, $gutters) {
  width: calc($gap * $gutters + $width * $multiples + 2rem);

  @media screen and (min-width: 730px) {
    width: calc($gap * $gutters + $width * $multiples + 1rem);
  }

  @media screen and (min-width: 1700px) {
    width: calc($gap * $gutters + $width * $multiples + 2rem);
  }
}

$card_height: 14.23006rem;
$card_width: 9.81381rem;
$card_gap: 1.7rem;

$card_height_730: 16.58075rem;
$card_width_730: 11.435rem;
$card_gap_730: 1rem;

$card_height_1400: 28.67431rem;
$card_width_1400: 19.77538rem;
$card_gap_1400: 1rem;

$card_height_1700: 32.625rem;
$card_width_1700: 22.5rem;
$card_gap_1700: 2rem;

.zunu_things_cards_container {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  //   gap: $card_gap;

  margin: 0 auto;
  align-items: center;
  justify-content: center;

  //   @include calcContainerWidth($card_gap, $card_width, 1, 0);
  width: 100%;
  padding-bottom: 3.9rem;
  // padding-left: 1.38rem;

  .carousel_left_arrow,
  .carousel_right_arrow {
    position: absolute;

    box-sizing: border-box;
    padding: 0;
    margin: 0;
    background: none;
    border: none;

    display: flex;
    height: max-content;

    // top: 70%;

    z-index: 0;
    bottom: 0;
    // padding-bottom: 2rem;

    cursor: pointer;
    // transform: translateY(-50%);

    svg {
      width: 2.62rem;
      height: 2.62rem;

      circle {
        fill: var(--arrow_fill_color);
      }
    }
  }

  .carousel_left_arrow {
    // left: 2rem;
    left: 20%;
  }

  .carousel_right_arrow {
    // right: 2rem;
    right: 20%;
  }

  .cards_carousel {
    position: initial;
    z-index: 0;

    display: flex;
    gap: 1rem;

    width: 100%;
    height: 20rem;
    bottom: 0;
    // padding-left: 1.38rem;

    // overflow-x: hidden !important;

    ul {
      height: inherit;
      display: flex;
      //   gap: $card_gap !important;
      //   gap: 2.2rem !important;
      //   gap: 1rem !important;

      user-select: none;

      //   display: grid;
      //   grid-template-columns: repeat(20, 1fr);

      li {
        height: inherit;
        list-style-type: none;
        animation: slide-in-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
      }
    }
  }


  @media screen and (min-width: 730px) {
    // @include calcContainerWidth($card_gap_730, $card_width_730, 3, 2);
    width: 100%;
    padding-bottom: 5.2rem;
    // padding-left: 2.34rem;

    .carousel_left_arrow,
    .carousel_right_arrow {
      // top: 50%;
      // bottom: 0;
      svg {
        width: 2.75rem;
        height: 2.75rem;
      }
    }

    .carousel_left_arrow {
      // left: 22rem;
      left: 40%;
    }

    .carousel_right_arrow {
      // right: 22rem;
      right: 40%;
    }

    .cards_carousel {
      height: 15.58075rem !important;
      padding-left: 2.34rem;

      //   ul {
      //     gap: $card_gap_730;
      //   }
    }
  }

  // @media screen and (min-width: 730px) {
  //   // @include calcContainerWidth($card_gap_730, $card_width_730, 3, 2);
  //   width: 100%;
  

  //   // .cards_carousel {
  //   //   height: 14rem !important;
  //   // }
  // }

  @media screen and (min-width: 1000px) {
    // @include calcContainerWidth($card_gap_730, $card_width_730, 4, 3);
    width: 100%;
    padding-bottom: 5.57rem;
    // padding-left: 2.88rem;

    .carousel_left_arrow,
    .carousel_right_arrow {
      svg {
        width: 3.75175rem;
        height: 3.75175rem;
      }
    }

    .carousel_left_arrow {
      // left: 22.25rem;
      left: 40%;
    }

    .carousel_right_arrow {
      // right: 22.25rem;
      right: 40%
    }

    .cards_carousel {
      height: 14rem !important;
      padding-left: 2.88rem;
    }
  }

  @media screen and (min-width: 1400px) {
    // @include calcContainerWidth($card_gap_1400, $card_width_1400, 4, 3);
    width: 100%;
    padding-bottom: 6.62rem;
    // padding-left: 10.5rem;

    .carousel_left_arrow,
    .carousel_right_arrow {
      svg {
        width: 4rem;
        height: 4rem;
      }
    }

    .carousel_left_arrow {
      // left: 23.25rem;
      left: 44%
    }

    .carousel_right_arrow {
      // right: 23.25rem;
      right: 44%;
    }
    .cards_carousel {
      height: 25rem !important;
      padding-left: 10.5rem;
    }
  }

  @media screen and (min-width: 1700px) {
    // @include calcContainerWidth($card_gap_1700, $card_width_1700, 4, 3);
    width: 100%;
    // padding-left: 18.75rem;

    .carousel_left_arrow,
    .carousel_right_arrow {
      svg {
        width: 4rem;
        height: 4rem;
      }
    }

    .carousel_left_arrow {
      // left: 22.25rem;
      left: 44%;
    }

    .carousel_right_arrow {
      // right: 22.25rem;
      right: 44%;
    }

    .cards_carousel {
      height: 30rem !important;
      padding-left: 18.75rem;
    }
  }

}

.carousel_left_arrow:disabled {
	opacity: 0.6;
	pointer-events: none;
}
.carousel_right_arrow:disabled {
	opacity: 0.6;
	pointer-events: none;
}