.supportPage {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 0 1.2rem;

	@media screen and (min-width: 730px) {
		padding: 0 2.5rem;
	}

	@media screen and (min-width: 1000px) {
		padding: 0 7rem;
	}

	@media screen and (min-width: 1400px) {
		padding: 0 10rem;
	}

	@media screen and (min-width: 1700px) {
		padding: 0 13.5rem;
	}
}

.pageHeading {
	color: var(--pricing_card_primary_text_color);
	font-weight: 600;
	text-align: center;
	font-size: 2rem;
	padding: 2.5rem 0 2rem 0;

	@media screen and (min-width: 730px) {
		font-size: 2.3rem;
		padding: 3rem 0 0rem 0;
	}

	@media screen and (min-width: 1000px) {
		font-size: 2.5rem;
		padding: 2.5rem 0 0rem 0;
	}

	@media screen and (min-width: 1400px) {
		font-size: 3rem;
		padding: 3.5rem 0 0rem 0;
	}

	@media screen and (min-width: 1700px) {
		font-size: 4rem;
		padding: 4.5rem 0 0rem 0;
	}
}

.dropdownWrapper {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	position: relative;
	padding: 0.75rem 1rem;
	border: 1px solid;
	background: var(--radial_bg);
	border: 1px solid var(--article_border);
}

.dropdownBar {
	width: inherit;
	display: flex;
	justify-content: space-between;

	div {
		display: flex;
		align-items: center;
		gap: 0.6rem;
		color: var(--pricing_card_primary_text_color);
		font-size: 1rem;
		font-weight: 600;

		svg {
			height: 1rem;

			path {
				stroke: none;
			}
		}
	}

	svg {
		width: 1rem;
		transform: rotate(0deg);

		path {
			stroke: var(--logo_color);
		}
	}
}

.rotate {
	transform: rotate(180deg) !important;
}

.dropdownOptions,
.dropdownWrapper {
	border-radius: 0.4rem;
}

.dropdownOptions {
	position: absolute;
	width: inherit;
	top: calc(100% + 0px);
	background: var(--radial_bg);
	border: 1px solid var(--article_border);
	z-index: 1;
	display: flex;
	// margin-top: .5rem;
	flex-direction: column;
	gap: 1.2rem;
	left: 0;
	padding: 0.75rem 1rem;
}

.dropdownOption {
	display: flex;
	align-items: center;
	gap: 0.9rem;
	font-size: 0.9rem;
	font-weight: 500;
	color: var(--pricing_card_primary_text_color);

	svg {
		height: 1.3rem;
	}
}

.productsSelector {
	width: 100%;
	display: flex;
	//   justify-content: space-between;
	margin-top: 3.5rem;
	gap: 3.5rem;
	gap: 2rem;

	@media screen and (min-width: 1000px) {
		margin-top: 4.3rem;
		// gap: 4rem;
		gap: 3rem;
	}

	@media screen and (min-width: 1400px) {
		margin-top: 6rem;
		// gap: 6rem;
		gap: 3.5rem;
	}

	@media screen and (min-width: 1700px) {
		margin-top: 8rem;
		// gap: 8rem;
		gap: 4rem;
	}
}

.product {
	display: flex;
	align-items: center;
	gap: 0.5rem;
	padding: 0.5rem;
	font-size: 0.8rem;
	color: var(--light_dark_gray);
	cursor: pointer;
	padding-bottom: 0.5rem;

	@media screen and (min-width: 1000px) {
		gap: 0.7rem;
		font-size: 1rem;
	}

	@media screen and (min-width: 1400px) {
		gap: 1rem;
		font-size: 1.2rem;
		padding-bottom: 0.8rem;
	}

	@media screen and (min-width: 1700px) {
		gap: 1.4rem;
		font-size: 1.4rem;
		padding-bottom: 1rem;
	}

	svg {
		height: 1.3rem;

		@media screen and (min-width: 1000px) {
			height: 1.5rem;
		}

		@media screen and (min-width: 1400px) {
			height: 1.7rem;
		}

		@media screen and (min-width: 1700px) {
			height: 2rem;
		}
	}
}

.faqContainer,
.topicQuestionsWrapper {
	background: var(--blue_bg);
	padding: 0.6rem 0.75rem;

	@media screen and (min-width: 730px) {
		padding: 1.5rem 0.8rem;
	}

	@media screen and (min-width: 1000px) {
		padding: 1.7rem 1rem;
	}

	@media screen and (min-width: 1400px) {
		border-radius: 1rem;
		padding: 2.5rem 1.5rem;
	}

	@media screen and (min-width: 1700px) {
		border-radius: 1.3rem;
		padding: 3.3rem 2rem;
	}
}

.faqContainer {
	width: 100%;
	border-radius: 0.6rem;
	display: flex;
	flex-direction: column;
	margin-top: 1rem;
	gap: 2rem;

	@media screen and (min-width: 730px) {
		// gap: 3.5rem;
		flex-direction: row-reverse;
	}

	@media screen and (min-width: 1000px) {
		// gap: 4rem;
	}

	@media screen and (min-width: 1400px) {
		border-radius: 1rem;
		// gap: 6rem;
	}

	@media screen and (min-width: 1700px) {
		border-radius: 1.3rem;
		// gap: 8rem;
	}
}

.faqWrapper {
	width: 100%;
	display: flex;
	flex-direction: column;

	@media screen and (min-width: 730px) {
		width: 55%;
	}

	@media screen and (min-width: 1000px) {
		width: 60%;
	}

	@media screen and (min-width: 1400px) {
		width: 65%;
	}

	@media screen and (min-width: 1400px) {
		width: 70%;
	}
}

.faqHeading {
	text-align: center;
	font-size: 1.25rem;
	font-weight: 500;
	color: var(--pricing_card_primary_text_color);
	border-bottom: 1px solid var(--light_stroke);
	padding-bottom: 0.5rem;

	@media screen and (min-width: 730px) {
		text-align: left;
		font-size: 1.2rem;
	}

	@media screen and (min-width: 1000px) {
		font-size: 1.4rem;
		padding-bottom: 1rem;
	}

	@media screen and (min-width: 1400px) {
		font-size: 1.8rem;
		padding-bottom: 1rem;
	}

	@media screen and (min-width: 1700px) {
		font-size: 2.5rem;
		padding-bottom: 1rem;
	}
}

.questionsWrapper {
	width: 100%;
	display: flex;
	flex-direction: column;
	margin-top: 1rem;

	@media screen and (min-width: 730px) {
		margin-top: 0rem;
	}

	@media screen and (min-width: 1000px) {
		margin-top: 0rem;
	}

	@media screen and (min-width: 1400px) {
		margin-top: 0rem;
	}

	@media screen and (min-width: 1700px) {
		margin-top: 0rem;
	}
}

.faqQuestions {
	width: inherit;
	display: flex;
	flex-direction: column;
	// gap: 1rem;
	// border: 1px solid red;
}

.faq_article_container {
	width: 100%;
	display: flex;
	flex-direction: column;
	border-bottom: 1px solid var(--light_stroke);
	padding: 0 1rem;

	@media screen and (min-width: 730px) {
		padding: 0 1.1rem;
	}

	@media screen and (min-width: 1000px) {
		padding: 0 1.1rem;
	}

	@media screen and (min-width: 1400px) {
		padding: 0 1.5rem;
	}

	@media screen and (min-width: 1700px) {
		padding: 0 2rem;
	}
}

.title_div {
	width: inherit;
	display: flex;
	justify-content: space-between;
	align-items: center;
	color: var(--pricing_card_primary_text_color);
	font-size: 0.9rem;
	gap: 0.8rem;
	font-weight: 500;

	@media screen and (min-width: 730px) {
		font-size: 1rem;
	}

	@media screen and (min-width: 1000px) {
		font-size: 1.1rem;
	}

	@media screen and (min-width: 1400px) {
		font-size: 1.2rem;
	}

	@media screen and (min-width: 1700px) {
		font-size: 1.4rem;
	}

	svg {
		width: 1rem;
		transform: rotate(0deg);

		@media screen and (min-width: 730px) {
			width: 0.8rem;
		}

		@media screen and (min-width: 1000px) {
			width: 0.9rem;
		}

		@media screen and (min-width: 1400px) {
			width: 1rem;
		}

		@media screen and (min-width: 1700px) {
			width: 1.3rem;
		}

		path {
			stroke: var(--logo_color);
		}
	}
}

.title_div,
.faq_article_contents {
	padding: 1rem 0 1rem 0;

	@media screen and (min-width: 730px) {
		padding: 0.8rem 0;
	}

	@media screen and (min-width: 1000px) {
		padding: 1.1rem 0;
	}

	@media screen and (min-width: 1400px) {
		padding: 1.5rem 0;
	}

	@media screen and (min-width: 1400px) {
		padding: 2rem 0;
	}
}

.title_active {
	color: var(--zunu_color);

	svg {
		transform: rotate(180deg);

		path {
			stroke: var(--zunu_color);
		}
	}
}

.faq_article_contents {
	width: 100%;
	border-top: 1px solid var(--light_stroke);
	font-size: 0.7rem;
	color: var(--pricing_card_primary_text_color);
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 0.5rem;

	@media screen and (min-width: 730px) {
		font-size: 0.75rem;
	}

	@media screen and (min-width: 1000px) {
		font-size: 0.8rem;
	}

	@media screen and (min-width: 1400px) {
		font-size: 0.9rem;
	}

	@media screen and (min-width: 1400px) {
		font-size: 1rem;
	}

	img {
		width: 50%;

		@media screen and (min-width: 730px) {
			width: 45%;
		}

		@media screen and (min-width: 1000px) {
			width: 55%;
		}

		@media screen and (min-width: 1400px) {
			width: 50%;
		}

		@media screen and (min-width: 1400px) {
			width: 45%;
		}
	}

	div {
		width: 100%;
	}

	ul,
	ol {
		margin-left: 1.8rem;

		::marker{
			font-size: .8rem !important;
		}
	}

	table {
		border-collapse: collapse;
		width: 100%;
	}

	td,
	th {
		border: 1px solid var(--light_stroke);
		text-align: left;
		padding: 8px;
	}
}

.joinASession {
	width: inherit;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	justify-content: flex-start;
	gap: 1.5rem;

	@media screen and (min-width: 730px) {
		width: 55%;
	}

	@media screen and (min-width: 1000px) {
		width: 50%;
	}

	@media screen and (min-width: 1400px) {
		width: 40%;
	}

	@media screen and (min-width: 1700px) {
		width: 30%;
	}

	img,
	figure {
		width: 100%;
	}

	img {
		aspect-ratio: 1.58/1;
	}

	.sessionHeading {
		color: var(--pricing_card_primary_text_color);
		font-weight: 500;
		font-size: 0.9rem;
		text-align: center;

		@media screen and (min-width: 730px) {
			font-size: 1.1rem;
		}

		@media screen and (min-width: 1000px) {
			font-size: 1.23rem;
		}

		@media screen and (min-width: 1400px) {
			font-size: 1.4rem;
		}

		@media screen and (min-width: 1400px) {
			font-size: 1.6rem;
		}

		span {
			color: var(--zunu_secondary_color);
		}
	}

	a {
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;

		@media screen and (min-width: 730px) {
			width: 85%;
		}

		@media screen and (min-width: 1000px) {
			width: 85%;
		}

		@media screen and (min-width: 1400px) {
			width: 95%;
		}

		@media screen and (min-width: 1400px) {
			width: 100%;
		}
	}

	button {
		width: inherit;
		display: flex;
		align-items: center;
		justify-content: center;
		text-align: center;
		background: var(--join_btn_bg);
		padding: 0.75rem 3rem;
		border-radius: 0.5rem;
		border: none;
		outline: none;
		gap: 0.8rem;
		font-size: 0.75rem;
		font-weight: 600;
		color: var(--text_color_white);
		box-shadow: var(--light_gray_shadow);
		cursor: pointer;

		@media screen and (min-width: 730px) {
			gap: 0.5rem;
			padding: 1rem 2.7rem;
			font-size: 0.7rem;
		}

		@media screen and (min-width: 1000px) {
			gap: 0.7rem;
			padding: 1rem 3.3rem;
			font-size: 0.8rem;
			border-radius: 1rem;
		}

		@media screen and (min-width: 1400px) {
			gap: 1rem;
			padding: 1.5rem 4.5rem;
			font-size: 1rem;
		}

		@media screen and (min-width: 1700px) {
			gap: 1.2rem;
			padding: 1.6rem 5rem;
			font-size: 1.4rem;
			border-radius: 1.3rem;
		}

		svg {
			height: 0.6rem;

			@media screen and (min-width: 1000px) {
				height: 1rem;
			}

			@media screen and (min-width: 1400px) {
				height: 1.2rem;
			}

			@media screen and (min-width: 1400px) {
				height: 1.6rem;
			}
		}
	}
}

.topicsContainer {
	width: inherit;
	display: flex;
	flex-direction: column;
	margin-top: 3rem;

	@media screen and (min-width: 730px) {
		margin-top: 3.5rem;
	}

	@media screen and (min-width: 1000px) {
		margin-top: 4rem;
	}

	@media screen and (min-width: 1400px) {
		margin-top: 6rem;
	}

	@media screen and (min-width: 1700px) {
		margin-top: 8rem;
	}
}

.topicsSelectionWrapper {
	display: flex;
	justify-content: space-between;
	gap: 1.5rem;
	margin-bottom: 0.75rem;
	gap: 2rem;

	@media screen and (min-width: 730px) {
		justify-content: flex-start;
		gap: 3.5rem;
	}

	@media screen and (min-width: 1000px) {
		gap: 4rem;
	}

	@media screen and (min-width: 1400px) {
		gap: 6rem;
	}

	@media screen and (min-width: 1700px) {
		gap: 8rem;
	}
}

.topic {
	display: flex;
	align-items: center;
	gap: 0.5rem;
	font-size: 0.75rem;
	padding-bottom: 0.5rem;
	font-weight: 500;
	color: var(--light_dark_gray);
	cursor: pointer;
	width: auto;
	white-space: nowrap;

	@media screen and (min-width: 730px) {
		gap: 0.6rem;
		font-size: 0.8rem;
	}

	@media screen and (min-width: 1000px) {
		gap: 0.7rem;
		font-size: 1rem;
	}

	@media screen and (min-width: 1400px) {
		gap: 1rem;
		font-size: 1.2rem;
	}

	@media screen and (min-width: 1700px) {
		gap: 1.3rem;
		font-size: 1.5rem;
	}

	svg {
		height: 0.75rem;

		@media screen and (min-width: 730px) {
			height: 1.1rem;
		}

		@media screen and (min-width: 1000px) {
			height: 1.1rem;
		}

		@media screen and (min-width: 1400px) {
			height: 1.4rem;
		}

		@media screen and (min-width: 1700px) {
			height: 1.5rem;
		}
	}
}

.product_active,
.topic_active {
	position: relative;
	transform: scale(1.05);
	font-weight: 600;
	color: var(--pricing_card_primary_text_color);

	@media screen and (min-width: 1000px) {
		transform: scale(1.1);
	}

	@media screen and (min-width: 1400px) {
		transform: scale(1.18);
	}

	@media screen and (min-width: 1400px) {
		transform: scale(1.2);
	}
}

.product_active::before,
.topic_active::before {
	content: '';
	position: absolute;
	display: block;
	bottom: 0;
	left: 10%;
	width: 80%;
	height: 4px;
	border-bottom: 4px solid var(--zunu_color);
	border-radius: 1rem;
}

.topicQuestionsWrapper {
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 1.2rem;

	@media screen and (min-width: 730px) {
		gap: 0;
	}
}

.topicImgWrapper {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;

	@media screen and (min-width: 730px) {
		display: none;
	}

	.topicImg {
		width: 80%;
	}
}

.questionsContainer,
.topicQuestions {
	width: 100%;
}

.banner {
	width: 100vw;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 1.5rem 1rem;
	background: var(--support_banner_bg);
	margin-top: 2.5rem;
	gap: 1.5rem;

	@media screen and (min-width: 730px) {
		flex-direction: row;
		margin-top: 4rem;
		padding: 2rem 2.5rem;
		gap: 4rem;
	}

	@media screen and (min-width: 1000px) {
		margin-top: 5rem;
		padding: 2rem 6rem;
		gap: 8rem;
	}

	@media screen and (min-width: 1400px) {
		margin-top: 7rem;
		padding: 2.5rem 8.5rem;
		gap: 10rem;
	}

	@media screen and (min-width: 1700px) {
		margin-top: 9.5rem;
		padding: 3.5rem 10rem;
		gap: 15rem;
	}
}

.bannerText {
	display: flex;
	flex-direction: column;
	color: var(--pricing_card_primary_text_color);
	gap: 1rem;

	@media screen and (min-width: 730px) {
		gap: 0.7rem;
	}

	@media screen and (min-width: 1000px) {
		gap: 0.9rem;
	}

	@media screen and (min-width: 1400px) {
		gap: 1.2rem;
	}

	@media screen and (min-width: 1700px) {
		gap: 1.5rem;
	}
}

.bannerHeading {
	font-weight: 600;
	font-size: 1rem;

	@media screen and (min-width: 730px) {
		font-size: 1.2rem;
	}

	@media screen and (min-width: 1000px) {
		font-size: 1.5rem;
	}

	@media screen and (min-width: 1400px) {
		font-size: 2rem;
	}

	@media screen and (min-width: 1700px) {
		font-size: 2.8rem;
	}
}

.bannerContent {
	font-size: 0.75rem;

	@media screen and (min-width: 730px) {
		font-size: 0.8rem;
	}

	@media screen and (min-width: 1000px) {
		font-size: 0.9rem;
	}

	@media screen and (min-width: 1400px) {
		font-size: 1rem;
	}

	@media screen and (min-width: 1700px) {
		font-size: 1.2rem;
	}
}

.bannerButtonWrapper {
	display: flex;
	justify-content: center;
	align-items: center;
}

.bannerButton {
	border: none;
	outline: none;
	background-color: var(--zunu_color);
	color: var(--text_color_white);
	cursor: pointer;
	border-radius: 0.75rem;
	font-size: 0.75rem;
	padding: 0.8rem 3.5rem;
	font-weight: 600;

	@media screen and (min-width: 730px) {
		width: 13rem;
		border-radius: 0.8rem;
		font-size: 0.9rem;
		padding: 0.8rem 2.5rem;
	}

	@media screen and (min-width: 1000px) {
		width: 15rem;
		border-radius: 1rem;
		font-size: 1rem;
		padding: 1rem 2.5rem;
	}

	@media screen and (min-width: 1400px) {
		width: 20rem;
		border-radius: 1.4rem;
		font-size: 1.2rem;
		padding: 1.4rem 4rem;
	}

	@media screen and (min-width: 1700px) {
		width: 25rem;
		border-radius: 1.8rem;
		font-size: 1.5rem;
		padding: 1.8rem 5.4rem;
	}
}

.modal {
	position: fixed;
	height: calc(100% - var(--nav_height));
	top: var(--nav_height);
	left: 0%;
	width: 100%;
	background-color: rgba(0, 0, 0, 0.243);
	z-index: 2;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.formContainer {
	background: var(--bg_color);
	padding: 2.5rem 2rem;
	width: 80%;
	border-radius: 1rem;
}

.formHeading {
	text-align: center;
	font-weight: 600;
	font-size: 2.5rem;
	color: var(--pricing_card_primary_text_color);
}

.form {
	display: flex;
	flex-direction: column;
	gap: 0.5rem;

	textarea {
		resize: none;
	}
}

.modall_Container {
	display: flex;
	height: 100vh;
	width: 100vw;
	position: fixed;
	top: 0;
	left: 0;
	justify-content: center;
	align-items: center;
	background: rgba(0, 0, 0, 0.53);
	z-index: 7;
	overflow: auto;

	.modall::-webkit-scrollbar {
		display: none;
	}
}

.modall {
	padding: 2.5rem;
	height: auto;
	width: 85%;
	top: var(--nav_height);
	justify-content: center;
	border: 1.778px solid #d4d4d4;
	position: absolute;
	background: #fff;
	border-radius: 1.5rem;
	box-shadow: 0px 0px 10.66667px 1.33333px rgba(0, 0, 0, 0.51);
	flex-shrink: 0;
	z-index: 3;
	overflow-y: auto;
	margin-bottom: 2rem;

	@media screen and (min-width: 320px) {
		height: 90%;
		width: 90%;
		padding: 1rem 0.3125rem;
		border-radius: 1rem;
		margin-bottom: 2rem;
	}

	@media screen and (min-width: 730px) {
		height: auto;
		padding: 1.44794rem;
		border-radius: 0.86875rem;
		margin-bottom: 3rem;
	}

	@media screen and (min-width: 1000px) {
		height: auto;
		width: 85%;
		padding: 1.77775rem;
		border-radius: 1.06669rem;
		margin-bottom: 4rem;
	}

	@media screen and (min-width: 1400px) {
		height: auto;
		width: 85%;
		padding: 2.5rem;
		border-radius: 1.5rem;
		margin-bottom: 5rem;
	}

	@media screen and (min-width: 1700px) {
		height: auto;
		width: 75%;
		padding: 3.33331rem;
		border-radius: 2rem;
		margin-bottom: 5rem;
	}

	.Close {
		display: flex;
		justify-content: right;
		position: absolute;
		top: 2%;
		right: 2%;

		.ClsBtn {
			background: none;
			border: none;
			cursor: pointer;
		}
		.CloseForm {
			width: 1.875rem;
			height: 1.875rem;

			@media screen and (min-width: 320px) {
				width: 1.25rem;
				height: 1.25rem;
			}

			@media screen and (min-width: 730px) {
				width: 1.0859rem;
				height: 1.0859rem;
			}

			@media screen and (min-width: 1000px) {
				width: 1.333rem;
				height: 1.333rem;
			}

			@media screen and (min-width: 1400px) {
				width: 1.875rem;
				height: 1.875rem;
			}

			@media screen and (min-width: 1700px) {
				width: 2.5rem;
				height: 2.5rem;
			}
		}
	}

	.Heading1 {
		display: flex;
		flex-direction: column;
		align-items: center;
		padding-bottom: 2rem;
		gap: 1.5rem;

		@media screen and (min-width: 320px) {
			gap: 1.5rem;
		}

		@media screen and (min-width: 730px) {
			gap: 0.87rem;
		}

		@media screen and (min-width: 1000px) {
			gap: 1.07rem;
		}

		@media screen and (min-width: 1400px) {
			gap: 1.5rem;
		}

		@media screen and (min-width: 1700px) {
			gap: 2rem;
		}

		.Heading {
			font-size: 2.25rem;
			font-weight: 600;
			font-style: normal;
			line-height: normal;

			@media screen and (min-width: 320px) {
				font-size: 1.25rem;
			}

			@media screen and (min-width: 730px) {
				font-size: 1.30313rem;
			}

			@media screen and (min-width: 1000px) {
				font-size: 1.6rem;
			}

			@media screen and (min-width: 1400px) {
				font-size: 2.25rem;
			}

			@media screen and (min-width: 1700px) {
				font-size: 3rem;
			}
		}

		.contents {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			justify-content: center;
			border-radius: 0.669rem;
			backdrop-filter: blur(5px);
		}
	}

	.FormNameAc {
		display: flex;
		flex-direction: row;
		align-items: flex-start;
		gap: 6.375rem;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
		width: 100%;

		@media screen and (min-width: 320px) {
			flex-direction: column;
			align-items: flex-start;
			gap: 2rem;
		}

		@media screen and (min-width: 730px) {
			flex-direction: row;
			gap: 3.69219rem;
		}

		@media screen and (min-width: 1000px) {
			flex-direction: row;
			gap: 4.53rem;
		}

		@media screen and (min-width: 1400px) {
			flex-direction: row;
			gap: 6.375rem;
		}

		@media screen and (min-width: 1700px) {
			flex-direction: row;
			gap: 8.5rem;
		}

		.formname {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			gap: 0.5rem;
			width: 100%;
		}

		.Name1 {
			display: flex;
			flex-direction: row;
			font-size: 1rem;
			gap: 0.5rem;
			font-weight: 400;
			align-items: center;

			p {
				display: flex;
				font-size: 1rem;
				font-weight: 400;
				color: #1c1b1f;

				@media screen and (min-width: 320px) {
					// display: none;
					font-size: 0.875rem;
				}

				@media screen and (min-width: 730px) {
					display: flex;
					font-size: 0.57919rem;
				}

				@media screen and (min-width: 1000px) {
					display: flex;
					font-size: 0.71113rem;
				}

				@media screen and (min-width: 1400px) {
					display: flex;
					font-size: 1rem;
				}

				@media screen and (min-width: 1700px) {
					display: flex;
					font-size: 1.33331rem;
				}
			}
			svg {
				width: 1.25rem;
				height: 1.25rem;

				@media screen and (min-width: 320px) {
					width: 1.25rem;
					height: 1.25rem;
				}

				@media screen and (min-width: 730px) {
					width: 0.72394rem;
					height: 0.72394rem;
				}

				@media screen and (min-width: 1000px) {
					width: 0.88888rem;
					height: 0.88888rem;
				}

				@media screen and (min-width: 1400px) {
					width: 1.25rem;
					height: 1.25rem;
				}

				@media screen and (min-width: 1700px) {
					width: 1.66669rem;
					height: 1.66669rem;
				}
			}
			// .EmailMobile {
			// 	display: flex;
			// 	font-size: 1rem;
			// 	font-weight: 400;
			// 	color: #1c1b1f;

			// 	@media screen and (min-width: 320px) {
			// 		display: flex;
			// 		font-size: 0.875rem;
			// 	}

			// 	@media screen and (min-width: 730px) {
			// 		display: none;
			// 		font-size: 0.57919rem;
			// 	}

			// 	@media screen and (min-width: 1000px) {
			// 		display: none;
			// 		font-size: 0.71113rem;
			// 	}

			// 	@media screen and (min-width: 1400px) {
			// 		display: none;
			// 		font-size: 1rem;
			// 	}

			// 	@media screen and (min-width: 1700px) {
			// 		display: none;
			// 		font-size: 1.33331rem;
			// 	}
			// }
		}

		input::placeholder {
			color: #9c9b9b;
		}

		input {
			width: 100%;
			display: flex;
			padding: 0.75rem 1rem;
			border-radius: 0.6669rem;
			font-size: 0.875rem;
			border: 1.778px solid #d4d4d4;
			font-weight: 300;
			justify-content: space-between;
			background: #f8faff;
			outline: none;

			@media screen and (min-width: 320px) {
				font-size: 0.75rem;
				padding: 0.75rem;
			}

			@media screen and (min-width: 730px) {
				font-size: 0.70675rem;
				padding: 0.43438rem 0.57919rem;
			}

			@media screen and (min-width: 1000px) {
				font-size: 0.82225rem;
				padding: 0.53331rem 0.71113rem;
			}

			@media screen and (min-width: 1400px) {
				font-size: 1rem;
				padding: 0.75rem 1rem;
			}

			@media screen and (min-width: 1700px) {
				font-size: 1.36669rem;
				padding: 1rem 1.33331rem;
			}
		}
	}

	.formAcc {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: center;
		gap: 0.5rem;
		width: 100%;

		@media screen and (min-width: 730px) {
			gap: 0.7rem;
		}

		@media screen and (min-width: 1000px) {
			gap: 0.65rem;
		}

		@media screen and (min-width: 1400px) {
			gap: 0.6rem;
		}

		@media screen and (min-width: 7100px) {
			gap: 0.5rem;
		}

		.Account1 {
			display: flex;
			flex-direction: row;
			gap: 0.5rem;
			font-size: 1rem;
			font-weight: 400;
			align-items: center;

			p {
				display: flex;
				font-size: 1rem;
				font-weight: 400;
				color: #1c1b1f;

				@media screen and (min-width: 320px) {
					// display: none;
					font-size: 0.875rem;
				}

				@media screen and (min-width: 730px) {
					display: flex;
					font-size: 0.57919rem;
				}

				@media screen and (min-width: 1000px) {
					display: flex;
					font-size: 0.71113rem;
				}

				@media screen and (min-width: 1400px) {
					display: flex;
					font-size: 1rem;
				}

				@media screen and (min-width: 1700px) {
					display: flex;
					font-size: 1.33331rem;
				}
			}
			.ZunuMailAC {
				width: 1.25rem;
				height: 1.25rem;

				@media screen and (min-width: 320px) {
					width: 1.25rem;
					height: 1.25rem;
				}

				@media screen and (min-width: 730px) {
					width: 0.72394rem;
					height: 0.72394rem;
				}

				@media screen and (min-width: 1000px) {
					width: 0.88888rem;
					height: 0.88888rem;
				}

				@media screen and (min-width: 1400px) {
					width: 1.25rem;
					height: 1.25rem;
				}

				@media screen and (min-width: 1700px) {
					width: 1.66669rem;
					height: 1.66669rem;
				}
			}
			// .ZunuAccMobile {
			// 	display: flex;
			// 	font-size: 1rem;
			// 	font-weight: 400;
			// 	color: #1c1b1f;

			// 	@media screen and (min-width: 320px) {
			// 		display: flex;
			// 		font-size: 0.875rem;
			// 	}

			// 	@media screen and (min-width: 730px) {
			// 		display: none;
			// 		font-size: 0.57919rem;
			// 	}

			// 	@media screen and (min-width: 1000px) {
			// 		display: none;
			// 		font-size: 0.71113rem;
			// 	}

			// 	@media screen and (min-width: 1400px) {
			// 		display: none;
			// 		font-size: 1rem;
			// 	}

			// 	@media screen and (min-width: 1700px) {
			// 		display: none;
			// 		font-size: 1.33331rem;
			// 	}
			// }
		}
	}

	input::placeholder {
		color: #9c9b9b;
	}
}

.FormOs {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 0.5rem;
	width: 100%;

	.Os1 {
		display: flex;
		flex-direction: row;
		gap: 0.5rem;
		font-size: 1rem;
		font-weight: 400;
		align-items: center;

		p {
			display: flex;
			font-size: 1rem;
			font-weight: 400;

			@media screen and (min-width: 320px) {
				display: none;
				font-size: 0.875rem;
			}

			@media screen and (min-width: 730px) {
				display: flex;
				font-size: 0.57919rem;
			}

			@media screen and (min-width: 1000px) {
				font-size: 0.71113rem;
				display: flex;
			}

			@media screen and (min-width: 1400px) {
				font-size: 1rem;
				display: flex;
			}

			@media screen and (min-width: 1700px) {
				font-size: 1.33331rem;
				display: flex;
			}
		}

		svg {
			width: 1.25rem;
			height: 1.25rem;

			@media screen and (min-width: 730px) {
				width: 0.72394rem;
				height: 0.72394rem;
			}

			@media screen and (min-width: 1000px) {
				width: 0.88888rem;
				height: 0.88888rem;
			}

			@media screen and (min-width: 1400px) {
				width: 1.25rem;
				height: 1.25rem;
			}

			@media screen and (min-width: 1700px) {
				width: 1.66669rem;
				height: 1.66669rem;
			}
		}

		.OSMobile {
			display: flex;
			font-size: 1rem;
			font-weight: 400;
			color: #1c1b1f;

			@media screen and (min-width: 320px) {
				font-size: 0.875rem;
				display: flex;
			}

			@media screen and (min-width: 730px) {
				font-size: 0.57919rem;
				display: none;
			}

			@media screen and (min-width: 1000px) {
				font-size: 0.71113rem;
				display: none;
			}

			@media screen and (min-width: 1400px) {
				font-size: 1rem;
				display: none;
			}

			@media screen and (min-width: 1700px) {
				font-size: 1.33331rem;
				display: none;
			}
		}
	}

	// os
	select {
		width: 100%;
		color: black;
		padding: 0.75rem 1rem;
		font-size: 0.875rem;
		border: 1.778px solid #d4d4d4;
		border-radius: 0.6669rem;
		background-image: url(../../assets/svg/arrow_drop_down_circle.svg);
		background-size: 1.25rem;
		background-repeat: no-repeat;
		background-position: center right 8px;
		-webkit-appearance: none;
		background-color: #f8faff;
		outline: none;

		@media screen and (min-width: 320px) {
			font-size: 0.75rem;
			padding: 0.75rem;
		}

		@media screen and (min-width: 730px) {
			font-size: 0.70675rem;
			padding: 0.43438rem 0.57919rem;
		}

		@media screen and (min-width: 1000px) {
			font-size: 0.82225rem;
			padding: 0.53331rem 0.71113rem;
		}

		@media screen and (min-width: 1400px) {
			font-size: 1rem;
			padding: 0.75rem 1rem;
		}

		@media screen and (min-width: 1700px) {
			font-size: 1.36669rem;
			padding: 1rem 1.33331rem;
		}
	}

	select:invalid {
		color: #9c9b9b;
	}
}

//product
.Product1 {
	display: flex;
	flex-direction: row;
	gap: 0.5rem;
	font-size: 1rem;
	font-weight: 400;
	align-items: center;

	@media screen and (min-width: 730px) {
		font-size: 0.57919rem;
	}

	@media screen and (min-width: 1000px) {
		font-size: 0.71113rem;
	}

	@media screen and (min-width: 1400px) {
		font-size: 1rem;
	}

	@media screen and (min-width: 1700px) {
		font-size: 1.33331rem;
	}

	svg {
		width: 1.25rem;
		height: 1.25rem;

		@media screen and (min-width: 730px) {
			width: 0.72394rem;
			height: 0.72394rem;
		}

		@media screen and (min-width: 1000px) {
			width: 0.88888rem;
			height: 0.88888rem;
		}

		@media screen and (min-width: 1400px) {
			width: 1.25rem;
			height: 1.25rem;
		}

		@media screen and (min-width: 1700px) {
			width: 1.66669rem;
			height: 1.66669rem;
		}
	}
}

select:invalid {
	color: #9c9b9b;
}

// topic
.Topic1 {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 0.5rem;
	font-size: 1rem;
	font-weight: 400;
	align-items: center;
	width: 100%;

	@media screen and (min-width: 730px) {
		font-size: 0.57919rem;
	}

	@media screen and (min-width: 1000px) {
		font-size: 0.71113rem;
	}

	@media screen and (min-width: 1400px) {
		font-size: 1rem;
	}

	@media screen and (min-width: 1700px) {
		font-size: 1.33331rem;
	}

	svg {
		width: 1.25rem;
		height: 1.25rem;

		@media screen and (min-width: 320px) {
			width: 1rem;
			height: 1rem;
		}

		@media screen and (min-width: 730px) {
			width: 0.72394rem;
			height: 0.72394rem;
		}

		@media screen and (min-width: 1000px) {
			width: 0.88888rem;
			height: 0.88888rem;
		}

		@media screen and (min-width: 1400px) {
			width: 1.25rem;
			height: 1.25rem;
		}

		@media screen and (min-width: 1700px) {
			width: 1.66669rem;
			height: 1.66669rem;
		}
	}
}
.Text1 {
	display: flex;
	align-items: center;
	width: 100%;
}

select::placeholder {
	color: #9c9b9b;
}

select:invalid {
	color: #9c9b9b;
}

.FormMsg {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 0.5rem;
	width: 100%;

	.Text1 {
		display: flex;
		position: relative;
		align-items: flex-end;
		width: 100%;

		p {
			display: flex;
			position: absolute;
			align-items: flex-end;
			font-size: 0.75rem;
			font-style: italic;
			font-weight: 400;
			line-height: normal;
			color: #9c9b9b;
			right: 0%;
			padding-bottom: 0.5rem;
			padding-right: 0.5rem;

			@media screen and (min-width: 320px) {
				font-size: 0.5rem;
				padding-right: 0.5rem;
			}

			@media screen and (min-width: 730px) {
				font-size: 0.43438rem;
				padding-right: 0.29rem;
			}

			@media screen and (min-width: 1000px) {
				font-size: 0.53331rem;
				padding-right: 0.35rem;
			}

			@media screen and (min-width: 1400px) {
				font-size: 0.75rem;
				padding-right: 0.5rem;
			}

			@media screen and (min-width: 1700px) {
				font-size: 1rem;
				padding-right: 0.7rem;
			}
		}
	}

	textarea::placeholder {
		color: #9c9b9b;
	}

	textarea {
		width: 100%;
		height: 10rem;
		padding: 1rem;
		font-size: 0.875rem;
		border: 1.778px solid #d4d4d4;
		border-radius: 0.6669rem;
		font-weight: 300;
		background: #f8faff;
		outline: none;

		@media screen and (min-width: 320px) {
			font-size: 0.75rem;
			padding: 0.75rem;
		}

		@media screen and (min-width: 730px) {
			font-size: 0.70675rem;
			padding: 0.43438rem 0.57919rem;
		}

		@media screen and (min-width: 1000px) {
			font-size: 0.82225rem;
			padding: 0.53331rem 0.71113rem;
		}

		@media screen and (min-width: 1400px) {
			font-size: 1rem;
			padding: 0.75rem 1rem;
		}

		@media screen and (min-width: 1700px) {
			font-size: 1.36669rem;
			padding: 1rem 1.33331rem;
		}
	}

	.MsgL {
		display: flex;
		flex-direction: row;
		font-size: 1rem;
		font-weight: 400;
		gap: 0.5rem;
		align-items: center;
		width: 100%;

		@media screen and (min-width: 730px) {
			font-size: 0.57919rem;
		}

		@media screen and (min-width: 1000px) {
			font-size: 0.71113rem;
		}

		@media screen and (min-width: 1400px) {
			font-size: 1rem;
		}

		@media screen and (min-width: 1700px) {
			font-size: 1.33331rem;
		}

		svg {
			width: 1.25rem;
			height: 1.25rem;

			@media screen and (min-width: 320px) {
				width: 1rem;
				height: 1rem;
			}

			@media screen and (min-width: 730px) {
				width: 0.72394rem;
				height: 0.72394rem;
			}

			@media screen and (min-width: 1000px) {
				width: 0.88888rem;
				height: 0.88888rem;
			}

			@media screen and (min-width: 1400px) {
				width: 1.25rem;
				height: 1.25rem;
			}

			@media screen and (min-width: 1700px) {
				width: 1.66669rem;
				height: 1.66669rem;
			}
		}
	}
}

.FormAttch {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 0.5rem;
	width: 100%;

	.Attach1 {
		display: flex;
		flex-direction: row;
		font-size: 1rem;
		font-weight: 400;
		gap: 0.5rem;
		align-items: center;

		@media screen and (min-width: 730px) {
			font-size: 0.57919rem;
		}

		@media screen and (min-width: 1000px) {
			font-size: 0.71113rem;
		}

		@media screen and (min-width: 1400px) {
			font-size: 1rem;
		}

		@media screen and (min-width: 1700px) {
			font-size: 1.33331rem;
		}

		svg {
			width: 1.25rem;
			height: 1.25rem;

			@media screen and (min-width: 320px) {
				width: 1.25rem;
				height: 1.25rem;
			}

			@media screen and (min-width: 730px) {
				width: 0.72394rem;
				height: 0.72394rem;
			}

			@media screen and (min-width: 1000px) {
				width: 0.88888rem;
				height: 0.88888rem;
			}

			@media screen and (min-width: 1400px) {
				width: 1.25rem;
				height: 1.25rem;
			}

			@media screen and (min-width: 1700px) {
				width: 1.66669rem;
				height: 1.66669rem;
			}
		}
	}

	.Text1 {
		display: flex;
		align-items: center;
		color: transparent;
		border: 1.778px solid #d4d4d4;
		border-radius: 0.6669rem;
		z-index: 2;
		width: 100%;
		padding: 0.7rem;
		position: relative;
		justify-content: space-between;
		background: #f8faff;
		outline: none;

		p {
			width: 95%;
			color: #9c9b9b;
			position: relative;
			font-size: 0.50675rem;
			z-index: 1;

			@media screen and (min-width: 320px) {
				font-size: 0.75rem;
				width: 78%;
				display: flex;
			}

			@media screen and (min-width: 730px) {
				font-size: 0.70675rem;
				width: 88%;
				display: flex;
			}

			@media screen and (min-width: 1000px) {
				font-size: 0.82225rem;
				width: 90%;
				display: flex;
			}

			@media screen and (min-width: 1400px) {
				font-size: 1rem;
				width: 92%;
				display: flex;
			}

			@media screen and (min-width: 1700px) {
				font-size: 1.36669rem;
				width: 89%;
				display: flex;
			}
		}
		.FileColor {
			display: flex;
			color: #1c1b1f;
		}

		svg {
			width: 1.25rem;
			height: 1.25rem;

			@media screen and (min-width: 320px) {
				width: 1.25rem;
				height: 1.25rem;
			}

			@media screen and (min-width: 730px) {
				width: 0.72394rem;
				height: 0.72394rem;
			}

			@media screen and (min-width: 1000px) {
				width: 0.88888rem;
				height: 0.88888rem;
			}

			@media screen and (min-width: 1400px) {
				width: 1.25rem;
				height: 1.25rem;
			}

			@media screen and (min-width: 1700px) {
				width: 1.66669rem;
				height: 1.66669rem;
			}
		}
	}

	input[type='file'] {
		width: 100%;
		font-size: 1rem;
		border: none;
		gap: 0.75rem;
		color: #1c1b1f;
		opacity: 0;
		z-index: 2;
		position: absolute;
		left: 0;
		top: 0;
		height: 100%;
		padding: 0;
		background: #f8faff;

		@media screen and (min-width: 320px) {
			font-size: 0.75rem;
		}

		@media screen and (min-width: 730px) {
			font-size: 0.50675rem;
		}

		@media screen and (min-width: 1000px) {
			font-size: 0.62225rem;
		}

		@media screen and (min-width: 1400px) {
			font-size: 0.875rem;
		}

		@media screen and (min-width: 1700px) {
			font-size: 1.16669rem;
		}
	}
}

.form {
	gap: 2rem;
	width: 100%;
	padding: 1rem;

	@media screen and (min-width: 730px) {
		gap: 1.16rem;
		padding: 0.57919rem;
	}

	@media screen and (min-width: 1000px) {
		gap: 1.42rem;
		padding: 0.71113rem;
	}

	@media screen and (min-width: 1400px) {
		gap: 2rem;
		padding: 1rem;
	}

	@media screen and (min-width: 1700px) {
		gap: 2.67rem;
		padding: 1.3rem;
	}
}

.ClearDwnld {
	display: flex;
	flex-direction: row;
	width: 100%;
	justify-content: space-between;
	align-items: flex-end;

	.ClrForm {
		height: auto;
		width: auto;
		font-size: 0.75rem;
		padding: 1rem 1.5rem;
		color: #9c9b9b;
		border-radius: 0.579rem;
		border: none;
		background: none;
		cursor: pointer;

		@media screen and (min-width: 320px) {
			padding: 0.75rem 0rem;
			font-size: 0.75rem;
		}

		@media screen and (min-width: 730px) {
			padding: 0.57919rem 0.86875rem;
			font-size: 0.63438rem;
		}

		@media screen and (min-width: 1000px) {
			padding: 0.71113rem 1.06669rem;
			font-size: 0.73331rem;
		}

		@media screen and (min-width: 1400px) {
			padding: 1rem 1.5rem;
			font-size: 0.95rem;
		}

		@media screen and (min-width: 1700px) {
			padding: 1.33331rem 2rem;
			font-size: 1.2rem;
		}
	}
}

.separatorLineMobile {
	display: flex;
	flex-direction: column;
	border: none;
	border: 1px solid #e8ecfd;

	@media screen and (min-width: 320px) {
		width: 100%;
	}

	@media screen and (min-width: 720px) {
		display: none;
		width: 100%;
	}

	@media screen and (min-width: 1000px) {
		display: none;
		width: 100%;
	}

	@media screen and (min-width: 1400px) {
		display: none;
		width: 100%;
	}

	@media screen and (min-width: 1700px) {
		display: none;
		width: 100%;
	}
}

.separatorLine1 {
	display: flex;
	flex-direction: column;
	border: none;
	border: 1px solid #e8ecfd;
	width: 100%;
	height: 0.08331rem;
}

.separatorLine {
	border: none;
	border: 1px solid #e8ecfd;
	width: 100%;
	height: 0.08331rem;
}

.Buttn {
	display: flex;
	font-size: 1rem;
	padding: 1rem 1.3rem;
	color: #fff;
	background: #497fef;
	font-weight: 600;
	border: none;
	border-radius: 0.579rem;
	cursor: pointer;

	@media screen and (min-width: 730px) {
		font-size: 0.72394rem;
		padding: 0.86875rem 1.15831rem;
	}

	@media screen and (min-width: 1000px) {
		font-size: 0.88888rem;
		padding: 0.9rem 1.2rem;
	}

	@media screen and (min-width: 1400px) {
		font-size: 1.25rem;
		padding: 1rem 1.3rem;
	}

	@media screen and (min-width: 1700px) {
		font-size: 1.6669rem;
		padding: 2rem 2.66669rem;
		padding: 1.2rem 1.5rem;
	}
}

.Buttn_disabled {
	cursor: not-allowed;
	background: #4980efa9;
}

.MailAccount {
	display: flex;
	position: relative;
	align-items: flex-end;
}

.ZUNU {
	display: flex;
	position: absolute;
	align-items: flex-start;
	right: 0.5px;
	border-radius: 0.06831rem;
	background: radial-gradient(
		117.65% 117.65% at 49.57% 44.9%,
		#fff 0%,
		#e6dfe7 100%
	);
}

.ErrorMsgD {
	display: flex;
	width: 100%;
}

.ErrorMsg {
	display: flex;
	font-size: 0.875rem;
	color: red;

	@media screen and (min-width: 320px) {
		font-size: 0.75rem;
	}

	@media screen and (min-width: 730px) {
		font-size: 0.70675rem;
	}

	@media screen and (min-width: 1000px) {
		font-size: 0.82225rem;
	}

	@media screen and (min-width: 1400px) {
		font-size: 1rem;
	}

	@media screen and (min-width: 1700px) {
		font-size: 1.36669rem;
	}
}

.ErrorBorder {
	textarea {
		border: 1.778px solid red !important;
	}
}

.fileErrorColor {
	display: flex;
	align-items: center;
	color: transparent;
	border: 1.778px solid red;
	border-radius: 0.6669rem;
	z-index: 2;
	width: 100%;
	padding: 0.7rem;
	position: relative;
	justify-content: space-between;

	p {
		display: flex;
		width: 95%;
		color: #9c9b9b;
		position: relative;
		font-size: 0.50675rem;
		z-index: 1;

		@media screen and (min-width: 320px) {
			font-size: 0.75rem;
			width: 78%;
			display: flex;
		}

		@media screen and (min-width: 730px) {
			font-size: 0.70675rem;
			width: 88%;
		}

		@media screen and (min-width: 1000px) {
			font-size: 0.82225rem;
			width: 90%;
		}

		@media screen and (min-width: 1400px) {
			font-size: 1rem;
			width: 92%;
		}

		@media screen and (min-width: 1700px) {
			font-size: 1.36669rem;
			width: 89%;
		}
	}
	svg {
		width: 1.25rem;
		height: 1.25rem;

		@media screen and (min-width: 320px) {
			width: 1rem;
			height: 1rem;
		}

		@media screen and (min-width: 730px) {
			width: 0.72394rem;
			height: 0.72394rem;
		}

		@media screen and (min-width: 1000px) {
			width: 0.88888rem;
			height: 0.88888rem;
		}

		@media screen and (min-width: 1400px) {
			width: 1.25rem;
			height: 1.25rem;
		}

		@media screen and (min-width: 1700px) {
			width: 1.66669rem;
			height: 1.66669rem;
		}
	}
}
