.terms {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 1.5rem;

  @media screen and (min-width: 730px) {
    padding: 0 2.75rem;
  }

  @media screen and (min-width: 1000px) {
    padding: 0 5.5rem;
  }

  @media screen and (min-width: 1400px) {
    padding: 0 6rem;
  }

  @media screen and (min-width: 1700px) {
    padding: 0 11.5rem;
  }
}

.pageHeading {
  color: var(--zunu_secondary_color);
  font-size: 1.5rem;
  padding: 2.2rem 0 2.5rem 0;
  font-weight: 600;
  text-align: center;

  @media screen and (min-width: 730px) {
    font-size: 2.2rem;
    padding: 7rem 0 2.5rem 0;
  }

  @media screen and (min-width: 1000px) {
    font-size: 2.5rem;
    padding: 4rem 0 3rem 0;
  }

  @media screen and (min-width: 1400px) {
    font-size: 3rem;
    padding: 6rem 0 3.5rem 0;
  }

  @media screen and (min-width: 1700px) {
    font-size: 3.3rem;
    padding: 8rem 0 5rem 0;
  }
}

.topicsSelector {
  display: flex;
  width: 100%;
  justify-content: space-between;
  color: var(--light_dark_gray);
  font-weight: 500;
  background-color: var(--bg_color);
  position: sticky;
  top: 3.5rem;

  @media screen and (min-width: 730px) {
    display: flex;
    top: 4rem;
    padding: 0 5rem;
    z-index: 5;
  }

  @media screen and (min-width: 1000px) {
    padding: 0 8rem;
  }

  @media screen and (min-width: 1200px) {
    top: 6rem;
  }

  @media screen and (min-width: 1400px) {
    top: 5rem;
    padding: 0 10rem;
  }

  @media screen and (min-width: 1700px) {
    top: 5rem;
    padding: 0 18rem;
  }
}

.topic {
  padding-bottom: 0.3rem;
  cursor: pointer;
  font-size: 0.8rem;

  @media screen and (min-width: 730px) {
    font-size: 1rem;
    padding-bottom: 0.5rem;
  }

  @media screen and (min-width: 1000px) {
    font-size: 1.2rem;
    padding-bottom: 0.8rem;
  }

  @media screen and (min-width: 1400px) {
    font-size: 1.5rem;
    padding-bottom: 1rem;
  }

  @media screen and (min-width: 1700px) {
    font-size: 1.6rem;
    padding-bottom: .8rem;
  }
}

.topic_active {
  position: relative;
  color: var(--zunu_primary_color);
}

.topic_active::before {
  content: "";
  position: absolute;
  display: block;
  bottom: 0;
  left: 5%;
  width: 90%;
  height: 4px;
  border-bottom: 4px solid var(--zunu_primary_color);
  border-radius: 1rem;
}

.productTermsWrapper {
  margin: 2rem 0;
  width: 100%;
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 730px) {
    margin: 5rem 0 3.5rem 0;
  }

  @media screen and (min-width: 1000px) {
    margin: 4rem 0 5rem 0;
  }

  @media screen and (min-width: 1400px) {
    margin: 6rem 0 6rem 0;
  }

  @media screen and (min-width: 1700px) {
    margin: 8rem 0 8rem 0;
  }

  .productTerms {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 1.5rem;

    @media screen and (min-width: 730px) {
      gap: 2rem;
    }

    @media screen and (min-width: 1000px) {
      gap: 2rem;
    }

    @media screen and (min-width: 1400px) {
      gap: 4rem;
    }

    @media screen and (min-width: 1700px) {
        gap: 4rem;
    }
  }
}

.point {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;

  @media screen and (min-width: 730px) {
    gap: 1rem;
  }

  @media screen and (min-width: 1000px) {
    gap: 1rem;
  }

  @media screen and (min-width: 1400px) {
  }

  @media screen and (min-width: 1700px) {
  }
}

.pointTitle {
  font-size: 1rem;
  font-weight: 600;
  color: var(--pricing_card_primary_text_color);

  @media screen and (min-width: 730px) {
    font-size: 1.3rem;
  }

  @media screen and (min-width: 1000px) {
    font-size: 1.5rem;
  }

  @media screen and (min-width: 1400px) {
    font-size: 1.6rem;
  }

  @media screen and (min-width: 1700px) {
    font-size: 1.7rem;
  }
}

.pointContent {
  font-size: 0.875rem;
  color: var(--pricing_card_primary_text_color);

  @media screen and (min-width: 730px) {
    font-size: 1rem;
  }

  @media screen and (min-width: 1000px) {
    font-size: 1rem;
  }

  @media screen and (min-width: 1400px) {
    font-size: 1.4rem;
  }

  @media screen and (min-width: 1700px) {
    font-size: 1.5rem;
  }
}
