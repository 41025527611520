.challangePage {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background-color: transparent;
	position: relative;
}

.challangeIntro {
	width: 100%;
	display: flex;
	flex-direction: column-reverse;
	justify-content: center;
	align-items: center;
	height: 75vh;
	background: linear-gradient(180deg, #205eff 0%, #b57aff 100%);
	background-size: 100% 100%;
	background-repeat: no-repeat;
	position: relative;
	z-index: 1;
	gap: 0rem;
	padding: 3.5rem;

	@media screen and (min-width: 730px) {
		flex-direction: row;
		height: 75vh;
		background-size: 100% 100%;
		gap: 0rem;
		padding: 0rem 2rem 0rem 4rem;
	}

	@media screen and (min-width: 1000px) {
		align-items: flex-end;
		background-size: 100% 100%;
		height: 75vh;
		gap: 0rem;
		padding: 0rem 1.5rem 0rem 5rem;
	}

	@media screen and (min-width: 1400px) {
		height: 75vh;
		gap: 0rem;
		padding: 0rem 1.5rem 0rem 5rem;
	}

	@media screen and (min-width: 1700px) {
		gap: 0rem;
		padding: 0rem 3rem 0rem 10rem;
	}

	.introText {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: center;
		color: white;
		height: 100%;
		font-weight: 700;
		font-size: 2rem;
		z-index: 1;
		width: 100%;
		margin-top: 0rem;
		gap: 1rem;

		@media screen and (min-width: 730px) {
			font-size: 2.3rem;
			margin-top: 0rem;
			gap: 1.2rem;
		}

		@media screen and (min-width: 1000px) {
			font-size: 2.8rem;
			// margin-top: -4rem;
			gap: 1.5rem;
		}

		@media screen and (min-width: 1400px) {
			font-size: 4rem;
			// margin-top: -8rem;
			gap: 2rem;
		}

		@media screen and (min-width: 1700px) {
			font-size: 5.3rem;
			// margin-top: -10rem;
			gap: 2.6rem;
		}

		button {
			width: auto;
			cursor: pointer;
			border: none;
			outline: none;
			padding: 0.8rem 1.2rem;
			border-radius: 50rem;
			color: white;
			font-size: 0.9rem;
			font-weight: 700;
			// background: linear-gradient(99deg, #3662ff 9.83%, #1fe4ff 91.33%);
			background: linear-gradient(97deg, #4200FF 15.33%, #0094FF 95.71%);
			// box-shadow: 0px 8px 3.1px 0px rgba(255, 255, 255, 0.25) inset,
			// 	0px -6px 4px 0px rgba(0, 0, 0, 0.25) inset;

			@media screen and (min-width: 730px) {
				padding: 0.7rem 1rem;
				font-size: 0.8rem;
			}

			@media screen and (min-width: 1000px) {
				padding: 0.9rem 1.3rem;
				font-size: 0.8rem;
			}

			@media screen and (min-width: 1400px) {
				padding: 1.2rem 1.8rem;
				font-size: 1.2rem;
			}

			@media screen and (min-width: 1700px) {
				padding: 1.6rem 2.4rem;
				font-size: 1.6rem;
			}
		}

		button:active {
			box-shadow: 0px 8px 3.1px 0px rgba(177, 177, 177, 0.25) inset,
				0px -6px 4px 0px rgba(0, 0, 0, 0.376) inset;
			transform: translateY(3px);
		}
	}

	.introImage {
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		z-index: 1;
		pointer-events: none;

		img {
			width: 100%;
		}
	}
}

.challangeIntro::before {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 75vh;
	background: url(../../assets/webp/binary_bg_white.webp);
	background-size: cover;
	opacity: 0.6;
	z-index: 0;

	@media screen and (min-width: 730px) {
		height: 75vh;
	}

	@media screen and (min-width: 1000px) {
		height: 75vh;
	}

	@media screen and (min-width: 1400px) {
		height: 75vh;
	}
}

.challengeDescription {
	width: 100%;
	display: flex;
	flex-direction: column;
	padding: 2rem;
	gap: 2rem;

	@media screen and (min-width: 730px) {
		padding: 4rem;
		gap: 2.8rem;
	}

	@media screen and (min-width: 1000px) {
		padding: 5rem;
		gap: 3.5rem;
	}

	@media screen and (min-width: 1400px) {
		padding: 7rem;
		gap: 4.8rem;
	}

	@media screen and (min-width: 1700px) {
		padding: 9rem;
		gap: 6.5rem;
	}

	.descriptionCategory {
		display: flex;
		flex-direction: column;
		gap: 1rem;

		@media screen and (min-width: 730px) {
			gap: 0.9rem;
		}

		@media screen and (min-width: 1000px) {
			gap: 1rem;
		}

		@media screen and (min-width: 1400px) {
			gap: 1.5rem;
		}

		@media screen and (min-width: 1700px) {
			gap: 2rem;
		}

		.categoryHeading {
			color: #14132d;
			font-size: 1rem;
			font-weight: 700;

			@media screen and (min-width: 730px) {
				font-size: 1.4rem;
			}

			@media screen and (min-width: 1000px) {
				font-size: 1.6rem;
			}

			@media screen and (min-width: 1400px) {
				font-size: 2.25rem;
			}

			@media screen and (min-width: 1700px) {
				font-size: 3rem;
			}

			span {
				background: linear-gradient(
					97deg,
					#0057ff 11.94%,
					#00a3ff 45.19%
				);
				background-clip: text;
				-webkit-background-clip: text;
				-webkit-text-fill-color: transparent;
			}

			.rewards {
				background: linear-gradient(112deg, #fff504 0.23%, #f09 73.36%);
				background-clip: text;
				-webkit-background-clip: text;
				-webkit-text-fill-color: transparent;
			}
		}

		.categoryText {
			color: #444343;
			font-size: 0.87rem;

			@media screen and (min-width: 730px) {
				font-size: 0.8rem;
			}

			@media screen and (min-width: 1000px) {
				font-size: 1rem;
			}

			@media screen and (min-width: 1400px) {
				font-size: 1.5rem;
			}

			@media screen and (min-width: 1700px) {
				font-size: 2rem;
			}
		}

		ul {
			margin-left: 1.2rem;
			display: flex;
			flex-direction: column;
			gap: 0.5rem;

			@media screen and (min-width: 730px) {
				margin-left: 1.2rem;
				gap: 0.5rem;
			}

			@media screen and (min-width: 1000px) {
				margin-left: 1.5rem;
				gap: 0.8rem;
			}

			@media screen and (min-width: 1400px) {
				margin-left: 1.5rem;
				gap: 1rem;
			}

			@media screen and (min-width: 1700px) {
				margin-left: 2rem;
				gap: 1.2rem;
			}
		}
	}
}