.redirect{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4rem;
    font-weight: 600;
    font-size: 1.5rem;

    @media screen and (min-width: 1400px) {
        font-size: 3rem; 
        padding: 6rem;
    }
    
}