@import '../../styles/abstracts';

.contact_us_section {
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  background: var(--bg_color);

  padding-top: 7.39rem;
  padding-top: 1.5rem;
  padding-bottom: 4rem;

  .contact_us_backdrop {
    position: absolute;
    background: var(--contact_us_bg);

    top: 0;
    left: 0;
    right: 0;

    height: 40vh;

    z-index: 0;
  }

  .contact_us_container {
    position: relative;
    z-index: 2;

    display: flex;
    flex-direction: column;

    border-radius: 1rem;
    background: var(--bg_color);
    box-shadow: 0px 2.45275px 9.19782px 0px rgba(140, 174, 245, 0.28);

    padding: 1.23rem;

    width: 99%;

    .contact_us_header {
      display: flex;
      flex-direction: column;
      gap: 0.4rem;

      text-align: center;
      width: 100%;

      h3 {
        color: var(--sub_text);
        font-size: 0.91981rem;
        font-style: normal;
        font-weight: 500;
      }

      p {
        color: var(--features_text);
        font-size: 0.61319rem;
        font-style: normal;
        font-weight: 400;
        line-height: 0.99644rem;
      }
    }

    .contact_us_main {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 3.6rem;

      .contact_us_illustration {
        display: flex;
        flex-wrap: wrap;
        flex: 0 0 1;
        align-items: flex-end;
        border-radius: 1rem;
        background: var(--contact_us_illustration_bg);
        padding-top: 2.34rem;

        margin-top: 2rem;

        svg {
          margin: 0 auto;
          width: 90%;
          height: auto;
        }

        .contact_us_registered_article {
          display: flex;
          flex-direction: column;
          gap: 0.92rem;

          margin: 0 auto;
          padding-left: 1.73rem;
          padding-right: 1rem;

          h3 {
            color: var(--sub_text);
            font-size: 0.91981rem;
            font-style: normal;
            font-weight: 400;
          }

          .contact_us_registered_locations {
            display: flex;
            flex-direction: column;
            gap: 1.23rem;

            .contact_us_registered_locations_cards {
              display: flex;
              flex-direction: column;
              gap: 0.3rem;

              h4 {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                width: max-content;

                color: var(--sub_text);
                font-size: 0.61319rem;
                font-style: normal;
                font-weight: 600;

                .icon_pin {
                  width: 0.91981rem;
                  height: 0.91975rem;

                  path {
                    fill: var(--sub_text);
                  }
                }
              }

              p {
                color: var(--registered_text);
                font-size: 0.53656rem;
                font-style: normal;
                font-weight: 400;
                line-height: 0.99644rem;
              }
            }
          }
        }
      }

      .contact_us_form {
        width: 90%;
        display: flex;
        flex-direction: column;
        gap: 1.23rem;

        padding-bottom: 2.45rem;

        .contact_us_form_button {
          display: grid;
          place-items: center;

          background: #4359f6;
          border: none;
          border-radius: 0.3rem;
          width: 35%;
          height: 1.9rem;

          color: #ffffff;
          font-size: 0.61319rem;
          font-style: normal;
          font-weight: 600;
          line-height: normal;

          cursor: pointer;

          .button_loader {
            text-align: center;
            font-size: 1.25rem;

            span:not(:last-child) {
              margin-right: 5px;
            }

            span {
              color: inherit;
              animation-name: opacity;
              animation-duration: 1s;
              animation-iteration-count: infinite;
            }

            span:nth-child(2) {
              -webkit-animation-delay: 100ms;
              -moz-animation-delay: 100ms;
            }

            span:nth-child(3) {
              -webkit-animation-delay: 300ms;
              -moz-animation-delay: 300ms;
            }
          }
        }
      }
    }
  }

  @media screen and (min-width: 730px) {
    .contact_us_backdrop {
      height: 32vh;
    }

    .contact_us_container {
      gap: 2.37rem;
      width: 80%;

      padding: 1.23rem 2.75rem 2.8rem 2.75rem;

      .contact_us_header {
        h3 {
          font-size: 1.02494rem;
        }

        p {
          font-size: 0.68331rem;
        }
      }

      .contact_us_main {
        flex-direction: row;
        gap: 3.1rem;

        .contact_us_illustration {
          margin-top: 0;
          width: 45%;

          .contact_us_registered_article {
            padding-left: 1.9rem;
            padding-right: 1.17rem;

            h3 {
              font-size: 1.02494rem;
            }

            .contact_us_registered_locations {
              .contact_us_registered_locations_cards {
                h4 {
                  font-size: 0.68331rem;

                  .icon_pin {
                    width: 1.02494rem;
                    height: 1.02494rem;
                  }
                }

                p {
                  font-size: 0.59788rem;
                  line-height: 1.11038rem;
                }
              }
            }
          }
        }

        .contact_us_form {
          padding-bottom: 0;
          width: 45%;

          gap: 1.37rem;

          .contact_us_form_button {
            height: 2rem;

            font-size: 0.68331rem;

            .button_loader {
              font-size: 1rem;
            }
          }
        }
      }
    }
  }

  @media screen and (min-width: 1000px) {
    .contact_us_container {
      .contact_us_header {
        h3 {
          font-size: 1.06463rem;
        }

        p {
          font-size: 0.70975rem;
        }
      }

      .contact_us_main {
        .contact_us_illustration {
          .contact_us_registered_article {
            h3 {
              font-size: 1.06463rem;
            }

            .contact_us_registered_locations {
              .contact_us_registered_locations_cards {
                h4 {
                  font-size: 0.70975rem;

                  .icon_pin {
                    width: 1.06463rem;
                    height: 1.06463rem;
                  }
                }

                p {
                  font-size: 0.62106rem;
                  line-height: 1.15331rem;
                }
              }
            }
          }
        }

        .contact_us_form {
          .contact_us_form_button {
            height: 2.12rem;

            font-size: 0.70975rem;

            .button_loader {
              font-size: 1.5rem;
            }
          }
        }
      }
    }
  }

  @media screen and (min-width: 1400px) {
    .contact_us_backdrop {
      height: 36vh;
    }

    .contact_us_container {
      border-radius: 1.5rem;
      padding: 2rem 4rem;

      gap: 3.38rem;

      .contact_us_header {
        h3 {
          font-size: 1.5rem;
        }

        p {
          font-size: 1rem;
        }
      }

      .contact_us_main {
        gap: unset;
        justify-content: space-between;

        .contact_us_illustration {
          border-radius: 1.5rem;

          .contact_us_registered_article {
            padding-top: 2.75rem;
            padding-left: 2.78rem;
            padding-right: 1.72rem;

            h3 {
              font-size: 1.5rem;
            }

            .contact_us_registered_locations {
              .contact_us_registered_locations_cards {
                h4 {
                  font-size: 1rem;

                  .icon_pin {
                    width: 1.5rem;
                    height: 1.5rem;
                  }
                }

                p {
                  font-size: 0.875rem;
                  line-height: 1.625rem;
                }
              }
            }
          }
        }

        .contact_us_form {
          .contact_us_form_button {
            height: 3rem;

            font-size: 1rem;
            border-radius: 0.5rem;

            .button_loader {
              font-size: 2rem;
            }
          }
        }
      }
    }
  }

  @media screen and (min-width: 1700px) {
    .contact_us_container {
      border-radius: 1.93331rem;
      padding: 2.58rem 5.16rem 5.16rem;

      gap: 4.35rem;

      .contact_us_header {
        h3 {
          font-size: 1.93331rem;
        }

        p {
          font-size: 1.28888rem;
        }
      }

      .contact_us_main {
        .contact_us_illustration {
          border-radius: 1.93331rem;

          .contact_us_registered_article {
            padding-top: 3rem;
            padding-left: 3.58rem;
            padding-right: 2.2rem;

            h3 {
              font-size: 1.93331rem;
            }

            .contact_us_registered_locations {
              .contact_us_registered_locations_cards {
                h4 {
                  font-size: 1.28888rem;

                  .icon_pin {
                    width: 1.93331rem;
                    height: 1.93331rem;
                  }
                }

                p {
                  font-size: 1.12775rem;
                  line-height: 2.09444rem;
                }
              }
            }
          }
        }

        .contact_us_form {
          .contact_us_form_button {
            height: 3.86rem;

            font-size: 1.28888rem;
            border-radius: 0.64444rem;

            .button_loader {
                font-size: 2.5rem;
              }
          }
        }
      }
    }
  }
}

